@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.term {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  font-style: normal; }

.term--uppercase {
  text-transform: uppercase; }

.term--lowercase {
  text-transform: lowercase; }

.term--alpha {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 11.5px;
  line-height: 18px;
  letter-spacing: 0.05em; }

.term--alpha.term--uppercase {
  letter-spacing: 0.09em; }

.term--medium.term--alpha {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 11.5px;
  line-height: 18px;
  letter-spacing: 0.06em; }

.term--medium.term--alpha.term--uppercase {
  letter-spacing: 0.1em; }

.term--bold.term--alpha {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.036em; }

.term--beta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em; }

.term--beta.term--uppercase {
  letter-spacing: 0.07em; }

.term--medium.term--beta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em; }

.term--medium.term--beta.term--uppercase {
  letter-spacing: 0.05em; }

.term--bold.term--beta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em; }

.term--gamma {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0; }

.term--gamma.term--uppercase {
  letter-spacing: 0.05em; }

.term--medium.term--gamma {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.004em; }

.term--medium.term--gamma.term--uppercase {
  letter-spacing: 0.05em; }

.term--bold.term--gamma {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.005em; }

.term--delta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em; }

.term--delta.term--uppercase {
  letter-spacing: 0.04em; }

.term--medium.term--delta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.002em; }

.term--medium.term--delta.term--uppercase {
  letter-spacing: 0.06em; }

.term--bold.term--delta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.004em; }

.term--epsilon {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.015em; }

.term--epsilon.term--uppercase {
  letter-spacing: 0.03em; }

.term--medium.term--epsilon {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.01em; }

.term--medium.term--epsilon.term--uppercase {
  letter-spacing: 0.03em; }

.term--bold.term--epsilon {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.013em; }

.term--zeta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: -0.02em; }

.term--zeta.term--uppercase {
  letter-spacing: 0.02em; }

.term--medium.term--zeta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: -0.013em; }

.term--medium.term--zeta.term--uppercase {
  letter-spacing: 0.03em; }

.term--bold.term--zeta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: -0.016em; }

.term--eta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 400;
  font-size: 62px;
  line-height: 66px;
  letter-spacing: -0.024em; }

.term--eta.term--uppercase {
  letter-spacing: 0.02em; }

.term--medium.term--eta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 62px;
  line-height: 66px;
  letter-spacing: -0.022em; }

.term--medium.term--eta.term--uppercase {
  letter-spacing: 0.03em; }

.term--bold.term--eta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 600;
  font-size: 62px;
  line-height: 66px;
  letter-spacing: -0.022em; }
