/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: calc(var(--spacing-unit) * 10);
  background-color: var(--gray-800);
  border-top: 1px solid var(--gray-900);
  @include shadow-floating;
  z-index: 100;
  align-items: center;
}

.footer__back {
  grid-column: 2 / 5;
  padding-right: calc(var(--spacing-unit) * 4);
  justify-self: start;
}

.footer__back--mobile {
  grid-column: 2 / 5;
  padding-right: calc(var(--spacing-unit) * 4);
  justify-self: start;
  display: none;
}

.footer__navigation {
  grid-column: 5/ 11;
  justify-self: center;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2);
}

.footer__link {
  display: block;
  @include typography('alpha');
  color: var(--gray-500);
  text-decoration: none;
  transition: color 0.2s;
  outline: none;
  padding: calc(var(--spacing-unit) * 0.5);
  border-radius: var(--border-radius);
}

.keyboard-focus .footer__link:focus {
  @include keyboard-focus;
}

.footer__link--is-active {
  color: var(--white);
}

.footer__separator {
  border-bottom: 1px solid var(--gray-900);
  width: calc(var(--spacing-unit) * 7);
  opacity: 0.5;
}

.footer__forward {
  padding-left: calc(var(--spacing-unit) * 4);
  grid-column: 11 / -2;
  justify-self: end;
}

@media (max-width: 1300px) {
  .footer__separator {
    width: calc(var(--spacing-unit) * 5);
  }

  .footer__navigation {
    column-gap: calc(var(--spacing-unit) * 1);
  }
}

@include for-tablet-landscape-down {
  .footer__link {
    display: none;
  }
  .footer__separator {
    display: none;
  }
}

@include for-tablet-portrait-down {
  .footer__separator {
    display: none;
  }

  .footer__link {
    display: none;
  }

  .footer__back {
    grid-column: 2 / 7;
  }

  .footer__navigation {
    grid-column: 7 / 9;
  }

  .footer__forward {
    grid-column: 9 / -2;
  }
}

@include for-phone-only {
  .footer__back {
    display: none;
  }

  .footer__back--mobile {
    grid-column: 2 / 8;
    display: block;
  }

  .footer__navigation {
    display: none;
  }

  .footer__forward {
    grid-column: 8 / -2;
  }

  .footer-button__label {
    display: none;
  }
}
