@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.button {
  --background-color: var(--gray-200);
  --background-color-active: #dddee4;
  --background-color-hover: var(--background-color);
  --color: var(--black);
  --icon-color: var(--gray-700);
  --border-color: rgba(0, 0, 0, 0.08);
  display: block;
  box-sizing: initial;
  padding: 0 calc(var(--spacing-unit) * 3);
  height: calc(var(--spacing-unit) * 6);
  max-width: max-content;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  border: none;
  background-color: var(--background-color);
  color: var(--color);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  transition: background-color 0.2s, opacity 0.2s, transform 0.2s border-color 0.2s;
  text-decoration: none;
  cursor: pointer; }
  .button:active {
    background-color: var(--background-color-active);
    transform: scale(0.98); }
  .button:hover:not(:active), .button[data-state*='open'] {
    background-color: var(--background-color-hover); }

.force-active .button {
  background-color: var(--background-color-active);
  transform: scale(0.98); }

.button--link {
  display: flex;
  align-items: center; }

.button__icon {
  float: left;
  margin-right: calc(var(--spacing-unit) * 1.5);
  margin-left: calc(-1 * var(--spacing-unit));
  height: 24px;
  padding-top: calc((24px - 16px) / 2);
  color: var(--icon-color); }

.button__icon-right {
  float: right;
  margin-left: calc(var(--spacing-unit) * 1.5);
  margin-right: calc(-1 * var(--spacing-unit));
  height: 24px;
  padding-top: calc((24px - 16px) / 2);
  color: var(--icon-color); }

.button:focus {
  outline: none; }

.keyboard-focus .button:focus,
.keyboard-focus.force-focus .button {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.button--disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none; }

.button--type-primary {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  --background-color: var(--highlight);
  --background-color-active: #1450dd;
  --color: var(--white);
  --icon-color: var(--white);
  --border-color: rgba(0, 0, 0, 0.4); }

.button--disabled.button--type-primary {
  --background-color: var(--gray-800); }

.button--type-destructive {
  --background-color: var(--stop);
  --background-color-active: #eb4d48;
  --color: var(--white);
  --icon-color: var(--black);
  --border-color: rgba(0, 0, 0, 0.35); }

.button--disabled.button--type-destructive {
  --background-color: var(--gray-600); }

.button--type-ghost,
.button--type-ghost-slim {
  --background-color: transparent;
  --background-color-active: #dddee4;
  --background-color-hover: #eeeef1;
  --color: var(--gray-600);
  --icon-color: var(--gray-700);
  --border-color: transparent;
  padding: 0 calc(var(--spacing-unit) * 2); }
  .button--type-ghost:hover, .button--type-ghost[data-state*='open'],
  .button--type-ghost-slim:hover,
  .button--type-ghost-slim[data-state*='open'] {
    --border-color: transparent;
    --color: var(--black); }

.keyboard-focus .button--type-ghost:focus,
.keyboard-focus .button--type-ghost-slim:focus {
  --background-color: var(--background-color-hover);
  --border-color: transparent; }

.button--disabled,
.button--disabled.button--type-ghost-slim {
  --background-color: transparent;
  opacity: 0.3; }

.button--type-ghost-slim {
  margin: calc(var(--spacing-unit) * -1) calc(var(--spacing-unit) * -2);
  border-width: 0; }

.button--theme-light {
  --background-color: rgba(0, 0, 0, 0.3);
  --background-color-active: rgba(0, 0, 0, 0.4);
  --color: var(--white);
  --icon-color: var(--white);
  --border-color: rgba(0, 0, 0, 0.15); }

.button--type-primary.button--theme-light {
  --background-color: var(--white);
  --background-color-active: #e6e6e6;
  --color: var(--black);
  --icon-color: var(--gray-700);
  --border-color: transparent; }

.button--type-ghost.button--theme-light,
.button--type-ghost-slim.button--theme-light {
  --background-color: transparent;
  --border-color: transparent;
  --color: rgba(255, 255, 255, 0.65);
  --background-color-active: rgba(255, 255, 255, 0.05);
  --background-color-hover: rgba(255, 255, 255, 0.1);
  --icon-color: rgba(255, 255, 255, 0.5); }
  .button--type-ghost.button--theme-light:hover, .button--type-ghost.button--theme-light[data-state*='open'],
  .button--type-ghost-slim.button--theme-light:hover,
  .button--type-ghost-slim.button--theme-light[data-state*='open'] {
    --color: var(--white);
    --border-color: transparent; }

.button--theme-dark-gray {
  --background-color: var(--gray-800);
  --background-color-active: #33353d;
  --color: var(--white);
  --icon-color: var(--gray-600);
  --border-color: rgba(255, 255, 255, 0.05); }

.button--type-primary.button--theme-dark-gray {
  --background-color: var(--highlight);
  --background-color-active: #1450dd;
  --color: var(--white);
  --icon-color: var(--white);
  --border-color: rgba(0, 0, 0, 0.4); }

.button--type-ghost.button--theme-dark-gray {
  --background-color: transparent;
  --border-color: transparent;
  --background-color-hover: #383943; }
  .button--type-ghost.button--theme-dark-gray:hover, .button--type-ghost.button--theme-dark-gray[data-state*='open'] {
    --border-color: rgba(255, 255, 255, 0.01); }

.button--theme-marketing {
  --background-color: var(--marketing);
  --background-color-active: #e1ea42;
  --color: var(--black);
  --icon-color: rgba(0, 0, 0, 0.5);
  --border-color: transparent; }

.button--disabled.button--theme-marketing {
  --background-color: #898989; }

.button--type-ghost.button--theme-marketing {
  --background-color: transparent;
  --border-color: transparent;
  --color: var(--white); }
  .button--type-ghost.button--theme-marketing:hover, .button--type-ghost.button--theme-marketing[data-state*='open'] {
    --background-color: var(--marketing); }

.button--theme-admin {
  --background-color: var(--admin);
  --background-color-active: #a41978;
  --color: var(--white);
  --icon-color: rgba(255, 255, 255, 0.5);
  --border-color: rgba(0, 0, 0, 0.45); }

.button--type-ghost.button--theme-admin {
  --background-color: transparent;
  --border-color: transparent;
  --color: var(--black); }
  .button--type-ghost.button--theme-admin:hover, .button--type-ghost.button--theme-admin[data-state*='open'] {
    --background-color: var(--admin); }

.button--theme-text {
  --background-color: transparent;
  --background-color-active: transparent;
  --color: var(--gray-600);
  --icon-color: var(--gray-700);
  --border-color: transparent;
  padding: 0;
  border: none;
  height: auto; }
  .button--theme-text:active {
    transform: scale(0.99); }

.button--type-primary.button--theme-text {
  --background-color: transparent;
  --background-color-active: transparent;
  --color: var(--highlight);
  --icon-color: var(--black);
  --border-color: transparent; }

.button--size-alpha {
  padding: 0 calc(var(--spacing-unit) * 1.5);
  height: calc(var(--spacing-unit) * 4.5);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em; }
  .button--size-alpha .button__icon {
    margin-right: calc(var(--spacing-unit) * 1);
    margin-left: 0;
    height: 12px;
    padding-top: calc((18px - 12px) / 2); }
  .button--size-alpha .button__icon-right {
    margin-left: calc(var(--spacing-unit) * 1);
    margin-right: calc(-0.5 * var(--spacing-unit));
    height: 12px;
    padding-top: calc((18px - 12px) / 2); }

.button--type-primary.button--size-alpha {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em; }

.button--type-ghost.button--size-alpha,
.button--type-ghost-slim.button--size-alpha {
  padding: 0 calc(var(--spacing-unit) * 1); }

.button--type-ghost-slim.button--size-alpha {
  margin: calc(var(--spacing-unit) * -0.75) calc(var(--spacing-unit) * -1); }

.button--theme-text.button--size-alpha {
  padding: 0; }

.button-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: calc(var(--spacing-unit) * 1);
  row-gap: calc(var(--spacing-unit) * 1); }
