/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.radar {
  position: absolute;
  height: 1px;
  width: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  --duration: 15;
  --size: 140;
  --background-color: rgba(0, 0, 0, 0.4);
  --border-color: rgba(0, 0, 0, 0.5); }

.radar--highlight {
  --background-color: rgba(37, 96, 235, 0.4);
  --border-color: rgba(37, 96, 235, 0.9); }

.radar--marketing {
  --background-color: rgba(246, 255, 81, 0.12);
  --border-color: rgba(246, 255, 81, 0.2); }

.radar__indicator--1,
.radar__indicator--2,
.radar__indicator--3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--spacing-unit) * var(--size));
  height: calc(var(--spacing-unit) * var(--size)); }

.radar__indicator--1::before,
.radar__indicator--1::after,
.radar__indicator--2::before,
.radar__indicator--2::after,
.radar__indicator--3::before,
.radar__indicator--3::after {
  content: '';
  display: block;
  position: absolute;
  transform: translate(50%, 50%);
  height: 100%;
  width: 100%;
  border-radius: calc(var(--spacing-unit) * var(--size) / 2);
  background-color: var(--background-color);
  border: 4px solid var(--border-color);
  animation-name: radar;
  animation-duration: calc(var(--duration) * 1s);
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.035, 0.37, 0.05, 0.33); }

.radar__indicator--1::before {
  animation-delay: 0; }

.radar__indicator--1::after {
  animation-delay: calc(var(--duration) / 6 * 1s); }

.radar__indicator--2::before {
  animation-delay: calc(var(--duration) / 6 * 2s); }

.radar__indicator--2::after {
  animation-delay: calc(var(--duration) / 6 * 3s); }

.radar__indicator--3::before {
  animation-delay: calc(var(--duration) / 6 * 4s); }

.radar__indicator--3::after {
  animation-delay: calc(var(--duration) / 6 * 5s); }

@keyframes radar {
  0% {
    opacity: 1;
    transform: scale(0); }
  40% {
    opacity: 0; }
  100% {
    opacity: 0;
    transform: scale(1); } }
