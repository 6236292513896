@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.modal {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-700);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4);
  margin: calc(var(--spacing-unit) * 5);
  width: calc(100% - (var(--spacing-unit) * 18));
  max-width: calc(var(--spacing-unit) * 100); }

.modal__form {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content; }

.modal__header {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-300);
  padding: 0 calc(var(--spacing-unit) * 1) 0 calc(var(--spacing-unit) * 2);
  min-height: calc(var(--spacing-unit) * 6);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center; }

.modal__subtitle {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  color: var(--gray-600); }

.modal__close-button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  cursor: pointer;
  padding: calc(var(--spacing-unit) * 1);
  border-radius: var(--border-radius);
  transition: all 0.3s; }

.keyboard-focus .modal__close-button:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.modal__close-button:active {
  background-color: var(--gray-200);
  transform: scale(0.85); }

.modal__body {
  padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 5);
  overflow-y: auto; }

.modal__footer {
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 5);
  background-color: var(--white);
  border-top: 1px solid var(--gray-300);
  display: flex;
  justify-content: flex-end;
  width: 100%; }

.modal--full-height {
  height: 100%; }

.modal--full-width {
  max-width: 100%; }

.modal--no-padding .modal__body {
  padding: 0; }

.modal--wide {
  max-width: calc(var(--spacing-unit) * 170); }

.modal--high {
  height: calc(var(--spacing-unit) * 105); }

.modal--theme-dark {
  background-color: var(--gray-900);
  border: 1px solid var(--gray-900);
  color: var(--white); }
  .modal--theme-dark .modal__header {
    border-bottom: 1px solid var(--black);
    background-color: var(--gray-800);
    color: var(--white); }
  .modal--theme-dark .modal__footer {
    border-top: 1px solid var(--black);
    background-color: var(--gray-800);
    color: var(--white); }
  .modal--theme-dark .modal__close-button:active {
    background-color: var(--gray-900); }

.modal--theme-white {
  background-color: var(--white); }
  .modal--theme-white .modal__footer {
    background-color: var(--gray-100); }
