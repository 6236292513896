/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.cue-list__container {
  padding-bottom: calc(var(--spacing-unit) * 10);
}

.cue-list {
  --cue-spacer: calc(var(--spacing-unit) * 5);
  position: relative;

  @include for-phone-only {
    --cue-spacer: calc(var(--spacing-unit) * 3);
  }
}

.cue-list__cue {
  transition: all 0.8s;
}

.cue-list__cue + .cue-list__cue {
  margin-top: var(--cue-spacer);
}

// .cue-list__cue:nth-child(2) {
//   margin-left: calc(var(--spacing-unit) * -2);
//   margin-right: calc(var(--spacing-unit) * -2);
// }

// .cue-list__cue:nth-child(2) .cue {
//   background-color: darken(map-get($secondary, 'lime-pie'), 22);
// }

// .cue-list__cue:nth-child(3) {
//   margin-left: calc(var(--spacing-unit) * -4);
//   margin-right: calc(var(--spacing-unit) * -4);
// }

// .cue-list__cue:nth-child(3) .cue {
//   background-color: darken(map-get($secondary, 'lime-pie'), 15);
// }

// .cue-list__cue:nth-child(4) {
//   margin-left: calc(var(--spacing-unit) * -6);
//   margin-right: calc(var(--spacing-unit) * -6);
// }

// .cue-list__cue:nth-child(4) .cue {
//   color: var(--lime-pie);
// }

.cue-list__button {
  position: absolute;
  bottom: calc(var(--spacing-unit) * 1.5);
  left: calc(var(--spacing-unit) * -2);
  cursor: pointer;
  right: 0;
  width: 100%;
  z-index: 2;
  @include button-reset;
  @include typography('beta', 'medium');

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.keyboard-focus .cue-list__button:focus {
  @include keyboard-focus;
}

// .cue-list--is-expanded {
//   padding-bottom: calc(var(--spacing-unit) * 13);

//   .cue-list__cue:nth-child(2) {
//     margin-left: 0;
//     margin-right: 0;
//   }

//   .cue-list__cue:nth-child(2) .cue {
//     background-color: var(--lime-pie);
//   }

//   .cue-list__cue:nth-child(3) {
//     margin-left: 0;
//     margin-right: 0;
//   }

//   .cue-list__cue:nth-child(3) .cue {
//     background-color: var(--lime-pie);
//   }

//   .cue-list__cue:nth-child(4) {
//     margin-left: 0;
//     margin-right: 0;
//   }

//   .cue-list__cue:nth-child(4) .cue {
//     color: inherit;
//   }

//   .cue-list__button {
//     left: 0;
//     bottom: calc(var(--spacing-unit) * 4);
//   }
// }
