@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.banner {
  border-radius: var(--border-radius);
  background-color: var(--gray-200);
  display: grid;
  grid-template-columns: max-content auto;
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 4);
  column-gap: calc(var(--spacing-unit) * 3);
  width: 100%; }

.banner__icon {
  padding-top: 5px;
  color: var(--highlight); }

.banner__text {
  align-self: center; }

.banner__headline {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em; }

.banner__message {
  color: var(--gray-700); }

.banner--no-headline .banner__message {
  color: var(--black); }

.banner--no-headline .banner__icon {
  padding: 0; }

.banner--caution,
.banner--stop {
  background-color: var(--gray-900); }
  .banner--caution .banner__headline,
  .banner--stop .banner__headline {
    color: var(--white); }
  .banner--caution .banner__message,
  .banner--stop .banner__message {
    color: var(--gray-400); }
  .banner--caution.banner--no-headline .banner__message,
  .banner--stop.banner--no-headline .banner__message {
    color: var(--white); }

.banner--caution {
  box-shadow: 0 0 0 2px var(--caution), 0 0 9px 0 var(--caution); }
  .banner--caution .banner__icon {
    color: var(--caution); }

.banner--stop {
  box-shadow: 0 0 0 2px var(--stop), 0 0 9px 0 var(--stop); }
  .banner--stop .banner__icon {
    color: var(--stop); }
