/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--spacing-unit) * -0.5); }

.tag {
  background-color: var(--gray-900);
  color: var(--white);
  border-radius: 2px;
  margin-right: calc(var(--spacing-unit) * 0.5);
  margin-bottom: calc(var(--spacing-unit) * 0.5);
  display: flex;
  align-items: center; }

.tag:last-child {
  margin-right: 0; }

.tag__icon {
  color: var(--highlight-light);
  padding-left: calc(var(--spacing-unit) * 1); }

.tag__input {
  padding-left: calc(var(--spacing-unit) * 1);
  padding-right: calc(var(--spacing-unit) * 1);
  height: calc(var(--spacing-unit) * 4.2);
  padding-top: 1px; }
  @media (max-width: 599px) {
    .tag__input {
      height: calc(var(--spacing-unit) * 3.5);
      padding-left: calc(var(--spacing-unit) * 1.5);
      padding-right: calc(var(--spacing-unit) * 0.5); } }

.keyboard-focus .tag__input:focus-within {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.tag__button {
  border: none;
  outline: none;
  padding: 6px calc(var(--spacing-unit) * 1);
  margin-right: 1px;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
  color: var(--gray-500); }

.tag__button:active {
  transform: scale(0.9); }

.keyboard-focus .tag__button:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.tag--is-editing .tag__input {
  pointer-events: all; }

.tag--is-add-button {
  background-color: var(--highlight);
  margin-left: calc(var(--spacing-unit) * 0.5); }

.tag--is-add-button .tag__button {
  padding: 6px calc(var(--spacing-unit) * 2);
  margin-left: 1px;
  margin-right: 1px;
  color: var(--white); }

.tag--is-add-button .tag__input {
  width: 0px;
  padding: 0;
  overflow: hidden; }
  .tag--is-add-button .tag__input input::placeholder {
    color: rgba(0, 0, 0, 0.4); }

.tag--is-add-button.tag--is-editing .tag__input {
  width: auto;
  padding-left: calc(var(--spacing-unit) * 1.5);
  padding-right: calc(var(--spacing-unit) * 0.5); }

.tag--is-add-button.tag--is-editing .tag__button {
  padding: 6px calc(var(--spacing-unit) * 1); }
