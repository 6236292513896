@charset "UTF-8";
/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.meta-info-button .button {
  height: unset;
  padding-top: calc(var(--spacing-unit) * 0.5);
  padding-bottom: calc(var(--spacing-unit) * 0.5);
  padding-left: calc(var(--spacing-unit) * 1.5);
  padding-right: calc(var(--spacing-unit) * 1.5);
  margin: calc(var(--spacing-unit) * -0.5) calc(var(--spacing-unit) * -1.5); }

.meta-info-button__content {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  column-gap: calc(var(--spacing-unit) * 1.5);
  align-items: center;
  text-align: left; }

.meta-info-button__value {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 22px;
  line-height: 30px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: -0.005em;
  color: var(--gray-600);
  font-variant-numeric: lining-nums;
  min-width: 1.1em;
  text-align: right; }
  @media (max-width: 599px) {
    .meta-info-button__value {
      font-size: 20px;
      line-height: 28px; } }

.meta-info-button--no-spacer .meta-info-button__value {
  min-width: unset; }

.meta-info-button__title {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em;
  color: var(--gray-700); }

.meta-info-button:hover .meta-info-button__title,
.keyboard-focus .meta-info-button:focus-within .meta-info-button__title {
  color: var(--gray-800);
  transition: all 0.15s; }

.meta-info-button__action {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: var(--gray-500);
  transition: all 0.15s; }

.meta-info-button:hover .meta-info-button__action,
.keyboard-focus .meta-info-button:focus-within .meta-info-button__action {
  color: var(--gray-600); }

.meta-info-button--highlight .meta-info-button__title {
  color: var(--gray-900); }

.meta-info-button--highlight .meta-info-button__value {
  color: var(--highlight); }

.meta-info-button--theme-light .button:hover,
.meta-info-button--theme-light .button:active {
  background-color: var(--white); }
