@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

@font-face {
  font-family: 'Söhne';
  src: url("__FONTS_EXTERNAL_URL__/soehne-web-leicht.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-web-leichte.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-web-leicht.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-web-leicht.woff") format("woff");
  font-weight: 300; }

@font-face {
  font-family: 'Söhne';
  src: url("__FONTS_EXTERNAL_URL__/soehne-web-buch.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-web-buch/filename.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-web-buch.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-web-buch.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Söhne';
  src: url("__FONTS_EXTERNAL_URL__/soehne-web-kraftig.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-web-kraftig.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-web-kraftig.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-web-kraftig.woff") format("woff");
  font-weight: 500; }

@font-face {
  font-family: 'Söhne Breit';
  src: url("__FONTS_EXTERNAL_URL__/soehne-breit-web-buch.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-breit-web-buch.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-breit-web-buch.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-breit-web-buch.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Söhne Breit';
  src: url("__FONTS_EXTERNAL_URL__/soehne-breit-web-kraftig.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-breit-web-kraftig.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-breit-web-kraftig.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-breit-web-kraftig.woff") format("woff");
  font-weight: 500; }

@font-face {
  font-family: 'Söhne Breit';
  src: url("__FONTS_EXTERNAL_URL__/soehne-breit-web-halbfett.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-breit-web-halbfett.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-breit-web-halbfett.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-breit-web-halbfett.woff") format("woff");
  font-weight: 600; }

@font-face {
  font-family: 'Söhne Mono';
  src: url("__FONTS_EXTERNAL_URL__/soehne-mono-web-buch.eot");
  src: url("__FONTS_EXTERNAL_URL__/soehne-mono-web-buch.eot?#iefix") format("embedded-opentype"), url("__FONTS_EXTERNAL_URL__/soehne-mono-web-buch.woff2") format("woff2"), url("__FONTS_EXTERNAL_URL__/soehne-mono-web-buch.woff") format("woff");
  font-weight: 400; }

*,
*::after,
*::before {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px; }

.app-frame {
  /* SPACING */
  --spacing-unit: 6px;
  --border-radius: 3px;
  /* COLORS */
  /* Grayscale */
  --white: #ffffff;
  --gray-100: #f9f9fb;
  --gray-150: #f0f1f5;
  --gray-200: #e8e9ed;
  --gray-300: #cdcfda;
  --gray-400: #b8bac7;
  --gray-500: #9da0af;
  --gray-600: #7b7e8e;
  --gray-700: #5e616e;
  --gray-800: #41434e;
  --gray-900: #1f2128;
  --gray-950: #16171d;
  --black: #000000;
  /* Primary */
  --highlight-light: #5588ff;
  --highlight: #2560eb;
  --highlight-dark: #1143b8;
  --highlight-background: #dee5f7;
  --stop: #ff5c57;
  --stop-dark: #cc3d33;
  --caution: #ffe657;
  --caution-dark: #837421;
  --go: #64dd7e;
  --go-dark: #328644;
  --keyboard-focus: #f4da7e;
  --meta: #e100e5;
  --support: #ffaca1;
  --support-dark: #c78379;
  --marketing: #f6ff51;
  --admin: #d90797;
  --admin-dark: #940b69;
  /* Secondary */
  --lemon-tarte: #fff6aa;
  --lemon-tarte-dark: #9e9651;
  --lemon-tarte-bright: #fff17c;
  --peach-crumble: #f9d6aa;
  --peach-crumble-dark: #af895a;
  --peach-crumble-bright: #f5be79;
  --lime-pie: #7de1cd;
  --lime-pie-dark: #2a9d86;
  --lime-pie-bright: #00ffcc;
  --blueberry-pancakes: #1599c1;
  --blueberry-pancakes-dark: #18647b;
  --blueberry-pancakes-bright: #0ac1f9;
  --plum-compote: #263b6b;
  --plum-compote-dark: #1a2338;
  --plum-compote-bright: #1d3d85;
  --raspberry-sorbet: #fc9c9c;
  --raspberry-sorbet-dark: #bd5f62;
  --raspberry-sorbet-bright: #ff585e;
  /* Secondary */
  --brand-red: #ff2c00;
  --brand-green: #00dbc2;
  --brand-green-dark: #00ad99;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em; }

.app-frame__floating {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; }

.app-frame--demo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
