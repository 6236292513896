@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.tab-bar {
  position: relative;
  margin-left: calc(var(--spacing-unit) * -0.5); }

.tab-bar__tab {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  cursor: pointer;
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 0.5);
  transition: opacity 0.2s;
  border-bottom: 2px solid transparent;
  color: var(--gray-700);
  position: relative;
  white-space: nowrap; }

.tab-bar__tab-title {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap; }

.tab-bar__tab-title-sizer {
  color: transparent; }

.tab-bar__tab + .tab-bar__tab {
  margin-left: calc(var(--spacing-unit) * 3); }

.tab-bar__tab--active {
  color: var(--black); }
  .tab-bar__tab--active .tab-bar__tab-title {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-underline-offset: 2px;
    font-weight: 500;
    letter-spacing: 0.016em; }

.tab-bar__tab:focus {
  outline: none; }

.keyboard-focus .tab-bar__tab:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color);
  border-radius: var(--border-radius); }

.tab-bar__tab:active {
  opacity: 0.6; }

.tab-bar__indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--highlight);
  transition: all 0.2s; }

.tab-bar--theme-light .tab-bar__tab {
  color: var(--gray-300); }

.tab-bar--theme-light .tab-bar__tab--active {
  color: var(--white); }

.tab-bar--theme-light .tab-bar__indicator {
  background-color: var(--highlight-light); }

.tab-bar--theme-highlight .tab-bar__tab {
  color: rgba(255, 255, 255, 0.5); }

.tab-bar--theme-highlight .tab-bar__tab--active {
  color: var(--white); }

.tab-bar--theme-highlight .tab-bar__indicator {
  background-color: var(--white); }

.tab-bar--theme-plum-compote .tab-bar__tab {
  color: rgba(255, 255, 255, 0.5); }

.tab-bar--theme-plum-compote .tab-bar__tab--active {
  color: var(--white); }

.tab-bar--theme-plum-compote .tab-bar__indicator {
  background-color: var(--lemon-tarte-bright); }
