/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.link {
  color: inherit;
  transition: all 0.3s;
  display: inline-block;
  text-decoration-skip-ink: auto; }

.keyboard-focus .link:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color);
  outline: none;
  border-radius: var(--border-radius); }

.link:active {
  opacity: 0.8;
  transform: scale(0.98); }

.link--button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  cursor: pointer;
  text-decoration: underline;
  margin: 0; }

.link--button + .link--button {
  margin-left: calc(1 * var(--spacing-unit)); }

.link--disabled {
  opacity: 0.3;
  pointer-events: none; }
