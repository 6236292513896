/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.visual-tooltip {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.2);
  border-radius: var(--border-radius);
  position: relative;
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2);
  background-color: var(--white);
  border-color: var(--gray-400);
  color: var(--black);
  pointer-events: all; }

.visual-tooltip__arrow {
  fill: var(--white); }

.visual-tooltip--theme-dark {
  background-color: var(--gray-800);
  border: 1px solid var(--gray-900);
  color: var(--white); }
  .visual-tooltip--theme-dark .visual-tooltip__arrow {
    fill: var(--gray-800); }

.visual-tooltip--theme-gray {
  background-color: var(--gray-300);
  border: 1px solid var(--gray-700);
  color: var(--black); }
  .visual-tooltip--theme-gray .visual-tooltip__arrow {
    fill: var(--gray-300); }

.visual-tooltip--no-padding {
  padding: 0; }
