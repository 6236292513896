@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.context-menu {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4);
  background-color: var(--gray-950);
  border-radius: var(--border-radius);
  color: var(--white);
  position: relative;
  max-width: calc(var(--spacing-unit) * 35);
  padding: 2px;
  pointer-events: all; }

.context-menu__item {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  width: 100%;
  color: var(--white);
  transition: all 0.2s; }

.context-menu__item:hover:not(.context-menu__item--disabled) {
  color: var(--white); }

.context-menu-button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.2s, transform 0.2s; }

.context-menu-button__content {
  padding: calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 1.25) calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 1.25);
  display: grid;
  grid-template-columns: 16px 1fr;
  column-gap: calc(var(--spacing-unit) * 1.5); }

.context-menu-button__icon {
  padding-top: 1.5px; }

.context-menu-button__subtitle {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 11px;
  line-height: 15px;
  text-underline-offset: 1px;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: var(--gray-600);
  grid-column: 2;
  padding-top: 3px; }

.context-menu-button:active {
  opacity: 0.8;
  transform: scale(0.97); }

.keyboard-focus .context-menu-button:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: inset 0 0 0 3px var(--focus-color), 0 0 6px 0 var(--focus-color); }

.context-menu-button--disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none; }

.context-menu-submenu {
  width: 100%;
  padding: calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 1.25);
  display: grid;
  grid-template-columns: 16px 1fr 12px;
  column-gap: calc(var(--spacing-unit) * 1.5);
  position: relative;
  align-items: start; }

.context-menu-submenu__icon {
  padding-top: 1.5px; }

.context-menu-submenu__arrow-right {
  padding-top: 3px; }

.context-menu-submenu__submenu {
  position: absolute;
  top: -2px;
  right: -3px;
  transform: translateX(100%);
  opacity: 0;
  width: max-content;
  max-width: calc(var(--spacing-unit) * 55); }

.context-menu-submenu:hover > .context-menu-submenu__submenu,
.context-menu-submenu:focus-within > .context-menu-submenu__submenu {
  opacity: 1; }

.keyboard-focus .context-menu-submenu:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: inset 0 0 0 3px var(--focus-color), 0 0 6px 0 var(--focus-color); }

.context-menu-submenu__submenu--wide > .context-menu {
  max-width: calc(var(--spacing-unit) * 50); }

.context-menu-select {
  width: 100%;
  padding: calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 1.25);
  display: grid;
  grid-template-columns: 16px 1fr 12px;
  column-gap: calc(var(--spacing-unit) * 1.5);
  position: relative;
  align-items: start; }

.context-menu-select__icon {
  padding-top: 1.5px; }

.context-menu-select__arrow-right {
  padding-top: 3px; }

.keyboard-focus .context-menu-select:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: inset 0 0 0 3px var(--focus-color), 0 0 6px 0 var(--focus-color); }

.context-menu-select__options {
  position: absolute;
  top: -2px;
  right: -3px;
  transform: translateX(100%);
  opacity: 0; }

.context-menu-select:hover > .context-menu-select__options,
.context-menu-select:focus-within > .context-menu-select__options {
  opacity: 1; }

.context-menu-select__option {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s, transform 0.2s; }

.context-menu-select__option:active {
  opacity: 0.8;
  transform: scale(0.97); }

.keyboard-focus .context-menu-select__option:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: inset 0 0 0 3px var(--focus-color), 0 0 6px 0 var(--focus-color); }

.context-menu-select__option-content {
  padding: calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 1.5);
  display: grid;
  grid-template-columns: 12px 1fr;
  column-gap: calc(var(--spacing-unit) * 1.5);
  align-items: center; }

.context-menu__item--separator {
  border-bottom: 1px solid var(--gray-800);
  margin-top: calc(var(--spacing-unit) * 0.5);
  margin-bottom: calc(var(--spacing-unit) * 0.5); }

.floating__wrapper--expand-left .context-menu-submenu__submenu,
.floating__wrapper--expand-left .context-menu-select__options {
  left: 1px;
  right: unset;
  transform: translateX(-100%); }
