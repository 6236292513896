@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.checklist {
  position: relative;
  --background-color: var(--go);
  --border-color: #5abf6f;
  --check-color: var(--white); }

.checklist__statement {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--spacing-unit) * 2); }

.checklist__statement-check {
  content: '';
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  display: block;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  color: var(--check-color);
  padding-top: 0.05em;
  padding-left: 0.3em;
  padding-bottom: 0.2em; }
  .checklist__statement-check-icon {
    transform: scale(1.2);
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.5)); }

.checklist--no-shadow .checklist__statement-check-icon {
  filter: none; }

.checklist__statement + .checklist__statement {
  margin-top: calc(var(--spacing-unit) * 2); }

.checklist--plum-compote .checklist__statement-check {
  --background-color: var(--plum-compote);
  --border-color: rgba(0, 0, 0, 0.12); }

.checklist--marketing {
  --background-color: var(--gray-900);
  --border-color: rgba(0, 0, 0, 0.12);
  --check-color: var(--marketing); }
