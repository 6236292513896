@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.inspiration-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--spacing-unit) * -1); }

.inspiration-item {
  background-color: var(--lemon-tarte);
  border: 1px solid #ffee5e;
  border-radius: calc(var(--spacing-unit) * 3);
  margin-right: calc(var(--spacing-unit) * 1);
  margin-bottom: calc(var(--spacing-unit) * 1);
  display: flex;
  align-items: center; }
  @media (max-width: 599px) {
    .inspiration-item {
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-size-adjust: none;
      text-size-adjust: none;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
      font-size: 13px;
      line-height: 18px;
      text-underline-offset: 1.5px;
      font-weight: 400;
      letter-spacing: 0.02em;
      margin-right: calc(var(--spacing-unit) * 0.5); } }

.inspiration-item:last-child {
  margin-right: 0; }

.inspiration-item__label {
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 2); }
  @media (max-width: 599px) {
    .inspiration-item__label {
      padding: calc(var(--spacing-unit) * 0.4) calc(var(--spacing-unit) * 1); } }

.inspiration-item__input {
  padding-left: calc(var(--spacing-unit) * 2);
  padding-right: calc(var(--spacing-unit) * 2);
  border-radius: 50px;
  height: calc(var(--spacing-unit) * 4.2);
  padding-top: 1px; }
  @media (max-width: 599px) {
    .inspiration-item__input {
      height: calc(var(--spacing-unit) * 3.5);
      padding-left: calc(var(--spacing-unit) * 1.5);
      padding-right: calc(var(--spacing-unit) * 0.5); } }

.keyboard-focus .inspiration-item__input:focus-within {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.inspiration-item__button {
  border: none;
  outline: none;
  padding: 6px calc(var(--spacing-unit) * 1);
  margin-right: 1px;
  border-radius: 40px;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s; }

.inspiration-item__button:active {
  background-color: var(--gray-300);
  transform: scale(0.9); }

.keyboard-focus .inspiration-item__button:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.inspiration-item--is-editing .inspiration-item__input {
  pointer-events: all; }

.inspiration-item--custom {
  background-color: var(--gray-200);
  border: 1px solid var(--gray-300); }

.inspiration-item--add {
  background-color: var(--gray-200);
  border: 1px solid var(--gray-300); }
  @media (max-width: 599px) {
    .inspiration-item--add {
      display: none; } }

.inspiration-item--add .inspiration-item__button {
  padding: 6px calc(var(--spacing-unit) * 2);
  margin-left: 1px;
  margin-right: 1px; }

.inspiration-item--add .inspiration-item__input {
  width: 0px;
  padding: 0;
  overflow: hidden; }

.inspiration-item--add.inspiration-item--is-editing .inspiration-item__input {
  width: auto;
  padding-left: calc(var(--spacing-unit) * 2);
  padding-right: calc(var(--spacing-unit) * 2); }

.inspiration-list--yellow .inspiration-item--custom {
  background-color: var(--lemon-tarte);
  border: 1px solid #ffee5e; }

.inspiration-list--yellow .inspiration-item__button:active {
  background-color: rgba(0, 0, 0, 0.07); }
