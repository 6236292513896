@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.slider {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2); }

.slider--with-labels {
  grid-template-columns: auto 1fr auto; }

.slider__slider {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  min-width: calc(var(--spacing-unit) * 30);
  cursor: pointer; }
  .slider__slider[data-orientation='horizontal'] {
    height: calc(var(--spacing-unit) * 3); }
  .slider__slider[data-orientation='vertical'] {
    flex-direction: column;
    width: calc(var(--spacing-unit) * 3);
    height: 1005; }

.slider__track {
  background-color: var(--gray-200);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px; }
  .slider__track[data-orientation='horizontal'] {
    height: calc(var(--spacing-unit) * 0.5); }
  .slider__track[data-orientation='vertical'] {
    width: calc(var(--spacing-unit) * 0.5); }

.slider__range {
  position: absolute;
  background-color: var(--highlight);
  border-radius: 9999px;
  height: 100%; }

.slider__thumb {
  all: unset;
  display: block;
  width: calc(var(--spacing-unit) * 3);
  height: calc(var(--spacing-unit) * 3);
  background-color: var(--white);
  border: 1px solid var(--gray-400);
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08);
  border-radius: 9999px;
  transition: all 0.2s; }
  .slider__thumb:active {
    transform: scale(0.9); }

.keyboard-focus .slider__thumb:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.slider__label {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em;
  color: var(--gray-800); }
  .slider__label--left {
    text-align: right; }

.slider--disabled {
  opacity: 0.3;
  cursor: default; }
  .slider--disabled .slider__range {
    background-color: var(--gray-600); }

.slider--invalid .slider__range {
  background-color: var(--stop); }

.slider--light .slider__track {
  background-color: var(--white); }
