/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.tooltip-container {
  position: absolute;
  top: calc(100% + 2px);
  right: 16px;
  transform: translateX(50%);
  pointer-events: none;

  width: max-content;
  z-index: 1;
}

.tooltip-container--is-visible {
  pointer-events: all;
}

.tooltip-container--left-top {
  top: 0%;
  right: -2px;
  transform: translate(100%, 0%);
}

.tooltip-container--left-center {
  top: 50%;
  right: -2px;
  transform: translate(100%, -50%);
}

.tooltip-container--left-bottom {
  top: 100%;
  right: -2px;
  transform: translate(100%, -100%);
}

.tooltip-container--right-top {
  top: 0%;
  left: -2px;
  transform: translate(-100%, 0%);
}

.tooltip-container--right-center {
  top: 50%;
  left: -2px;
  transform: translate(-100%, -50%);
}

.tooltip-container--right-bottom {
  top: 100%;
  left: -2px;
  transform: translate(-100%, -100%);
}

.tooltip-container__action {
  display: block;
  @include button-reset;
  cursor: pointer;
  width: 100%;
  border-radius: var(--border-radius);
  transition: all 0.2s;

  &:active {
    opacity: 0.8;
    transform: scale(0.98);
  }
}

.keyboard-focus .tooltip-container__action:focus {
  @include keyboard-focus;
}

.tooltip-container__action + .tooltip-container__action {
  margin-top: calc(var(--spacing-unit) * 1);
}
