@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.entity-link-button {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  font-variant-numeric: tabular-nums;
  border-radius: calc(var(--border-radius) * 2);
  padding: calc(var(--spacing-unit) * 0.25) calc(var(--spacing-unit) * 0.9);
  text-decoration: none;
  border: 1px solid transparent;
  background-color: #afece0;
  color: #1a6152;
  cursor: pointer;
  transition: transform 0.2s;
  display: block; }

.entity-link-button--question {
  background-color: #c1cde9;
  color: #1a2338; }

.entity-link-button--product {
  background-color: #fcebd5;
  color: #82643f; }

.entity-link-button--person {
  background-color: #fff8b9;
  color: #6b6637; }

.entity-link-button--action-item {
  background-color: #fed7d7;
  color: #a54447; }

.entity-link-button--group {
  background-color: #636678;
  color: var(--gray-800);
  position: relative;
  padding-right: calc(var(--spacing-unit) * 1.9);
  z-index: 1; }
  .entity-link-button--group::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: calc(var(--border-radius) * 2);
    right: calc(var(--spacing-unit) * 0.33);
    background-color: #9297af;
    border: 1px solid #959ab1; }
  .entity-link-button--group::after {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: calc(var(--border-radius) * 2);
    right: calc(var(--spacing-unit) * 0.66 + 1px);
    background-color: #cdcfda;
    border: 1px solid #bec1cf; }

.entity-link-button:focus {
  outline: none; }

.keyboard-focus .entity-link-button:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.entity-link-button:active {
  transform: scale(0.93); }

.entity-link-button--theme-minimal {
  background-color: transparent;
  border-color: var(--lime-pie);
  border-width: 2px; }
  .entity-link-button--theme-minimal.entity-link-button--question {
    border-color: #9bafdb; }
  .entity-link-button--theme-minimal.entity-link-button--product {
    border-color: var(--peach-crumble); }
  .entity-link-button--theme-minimal.entity-link-button--person {
    border-color: #e8d95b; }
  .entity-link-button--theme-minimal.entity-link-button--action-item {
    border-color: var(--raspberry-sorbet); }
  .entity-link-button--theme-minimal.entity-link-button--group {
    border: 1px solid #898c9e; }
    .entity-link-button--theme-minimal.entity-link-button--group::before {
      background-color: transparent;
      border: 1px solid #a1a5b9; }
    .entity-link-button--theme-minimal.entity-link-button--group::after {
      background-color: transparent;
      border: 1px solid #bec1cf; }

.entity-link-button--theme-bright {
  background-color: rgba(0, 255, 204, 0.27);
  color: var(--lime-pie-bright); }
  .entity-link-button--theme-bright.entity-link-button--question {
    background-color: rgba(29, 61, 133, 0.56);
    color: #7495e1; }
  .entity-link-button--theme-bright.entity-link-button--product {
    background-color: rgba(245, 190, 121, 0.27);
    color: var(--peach-crumble-bright); }
  .entity-link-button--theme-bright.entity-link-button--person {
    background-color: rgba(255, 241, 124, 0.27);
    color: var(--lemon-tarte-bright); }
  .entity-link-button--theme-bright.entity-link-button--action-item {
    background-color: rgba(255, 88, 94, 0.27);
    color: #ff8b8f; }
  .entity-link-button--theme-bright.entity-link-button--group {
    background-color: rgba(255, 255, 255, 0.2);
    color: var(--white); }
    .entity-link-button--theme-bright.entity-link-button--group::before {
      background-color: rgba(255, 255, 255, 0.2);
      border: none; }
    .entity-link-button--theme-bright.entity-link-button--group::after {
      background-color: rgba(255, 255, 255, 0.2);
      border: none; }

.entity-link-button--size-tiny {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 11px;
  line-height: 15px;
  text-underline-offset: 1px;
  font-weight: 400;
  letter-spacing: 0.03em;
  padding: 1px calc(var(--spacing-unit) * 0.75); }

.entity-link-button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: calc(var(--spacing-unit) * 0.5); }
