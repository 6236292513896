@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.card {
  border-radius: var(--border-radius);
  background-color: var(--gray-200);
  overflow: hidden;
  width: 100%;
  color: var(--black); }

.card__text {
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3); }

.card__headline {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  margin-bottom: calc(var(--spacing-unit) * 0.5); }

.card__message {
  color: rgba(0, 0, 0, 0.7);
  max-width: 36em; }

.card--no-headline .card__message {
  color: var(--black); }

.card__footer {
  background-color: var(--gray-300);
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2);
  display: flex;
  justify-content: flex-end; }

.card--theme-lime-pie {
  background-color: var(--lime-pie); }
  .card--theme-lime-pie .card__footer {
    background-color: var(--lime-pie-dark); }

.card--theme-plum-compote {
  background-color: var(--plum-compote);
  color: var(--white); }
  .card--theme-plum-compote .card__message {
    color: rgba(255, 255, 255, 0.8); }
  .card--theme-plum-compote.card--no-headline .card__message {
    color: var(--white); }
  .card--theme-plum-compote .card__footer {
    background-color: var(--plum-compote-dark); }

.card--theme-blueberry-pancakes {
  background-color: var(--blueberry-pancakes);
  color: var(--white); }
  .card--theme-blueberry-pancakes .card__message {
    color: rgba(255, 255, 255, 0.91); }
  .card--theme-blueberry-pancakes.card--no-headline .card__message {
    color: var(--white); }
  .card--theme-blueberry-pancakes .card__footer {
    background-color: var(--blueberry-pancakes-dark); }

.card--theme-raspberry-sorbet {
  background-color: var(--raspberry-sorbet); }
  .card--theme-raspberry-sorbet .card__footer {
    background-color: var(--raspberry-sorbet-dark); }

.card--theme-peach-crumble {
  background-color: var(--peach-crumble); }
  .card--theme-peach-crumble .card__footer {
    background-color: var(--peach-crumble-dark); }

.card--theme-lemon-tarte {
  background-color: var(--lemon-tarte); }
  .card--theme-lemon-tarte .card__footer {
    background-color: var(--lemon-tarte-dark); }

.card--theme-support {
  background-color: var(--support); }
  .card--theme-support .card__footer {
    background-color: var(--support-dark); }

.card--theme-highlight {
  background-color: var(--highlight);
  color: var(--white); }
  .card--theme-highlight .card__message {
    color: rgba(255, 255, 255, 0.86); }
  .card--theme-highlight.card--no-headline .card__message {
    color: var(--white); }
  .card--theme-highlight .card__footer {
    background-color: var(--highlight-dark); }

.card--theme-dark {
  background-color: var(--gray-800);
  color: var(--white); }
  .card--theme-dark .card__message {
    color: rgba(255, 255, 255, 0.86); }
  .card--theme-dark.card--no-headline .card__message {
    color: var(--white); }
  .card--theme-dark .card__footer {
    background-color: var(--gray-900); }

.card--compact {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3);
  column-gap: calc(var(--spacing-unit) * 3);
  align-items: center; }
  .card--compact .card__text {
    padding: 0; }
  .card--compact .card__footer {
    padding: 0;
    background-color: transparent; }
