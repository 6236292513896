/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

body {
  background-color: #fff;
}

.grid {
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 9) repeat(12, 1fr) calc(var(--spacing-unit) * 9);
}

@media (min-width: 1548px) {
  .grid {
    grid-template-columns: 1fr repeat(12, calc(var(--spacing-unit) * 20)) 1fr;
  }
}

.grid {
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 10) repeat(12, 1fr) calc(var(--spacing-unit) * 10);

  @media (min-width: 1440px) {
    grid-template-columns: 1fr repeat(12, 110px) 1fr;
  }

  @include for-tablet-portrait-down {
    grid-template-columns: calc(var(--spacing-unit) * 5) repeat(12, 1fr) calc(var(--spacing-unit) * 5);
  }

  @include for-phone-only {
    grid-template-columns: calc(var(--spacing-unit) * 3) repeat(12, 1fr) calc(var(--spacing-unit) * 3);
  }
}

a {
  color: inherit;
}
