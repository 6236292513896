@charset "UTF-8";
/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.toolbar {
  height: calc(var(--spacing-unit) * 6);
  background-color: var(--white);
  color: var(--black);
  border-bottom: 1px solid var(--gray-300);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: calc(var(--spacing-unit) * 2);
  justify-content: space-between;
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4);
  z-index: 20;
  position: relative; }

.toolbar__actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: calc(var(--spacing-unit) * 2);
  align-items: center;
  padding-left: calc(var(--spacing-unit) * 3); }

.toolbar__button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: var(--border-radius); }

.toolbar__button--disabled {
  opacity: 0.3;
  cursor: default; }

.toolbar__button-content {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  column-gap: calc(var(--spacing-unit) * 1);
  align-items: center;
  padding: 3px 4px; }

.keyboard-focus .toolbar__button:focus {
  outline: none;
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.toolbar__button--active,
.toolbar__button:active {
  background-color: var(--gray-200);
  transform: scale(0.97); }

.toolbar__button--icon-only .toolbar__button-content {
  padding: 3px 6px; }

.toolbar__button--more {
  background-color: var(--gray-200);
  margin-left: calc(var(--spacing-unit) * 1); }
  .toolbar__button--more.toolbar__button--active, .toolbar__button--more.toolbar__button:active {
    background-color: var(--gray-300);
    transform: scale(0.93); }

.toolbar__view-modes {
  padding-right: calc(var(--spacing-unit) * 1);
  padding-left: calc(var(--spacing-unit) * 1);
  border-left: 1px solid var(--gray-300);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 0.75);
  height: calc(var(--spacing-unit) * 6); }

.toolbar__view-mode-button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  padding: 3px 4px;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: all 0.2s; }

.keyboard-focus .toolbar__view-mode-button:focus {
  outline: none;
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.toolbar__view-mode-button:active {
  background-color: var(--gray-200);
  transform: scale(0.9); }

.toolbar__view-mode-button-content {
  display: block;
  opacity: 0.4;
  pointer-events: none; }

.toolbar__view-mode-button--active .toolbar__view-mode-button-content {
  opacity: 1; }

.toolbar__view-mode-separator {
  border-left: 1px solid var(--gray-300);
  height: calc(var(--spacing-unit) * 6);
  margin-right: calc(var(--spacing-unit) * 0.25);
  margin-left: calc(var(--spacing-unit) * 0.25); }

.toolbar--dark {
  border-bottom-color: var(--black);
  background-color: var(--gray-900);
  color: var(--white); }

.toolbar--dark .toolbar__button:active {
  background-color: var(--black); }

.toolbar--dark .toolbar__view-mode-button:active {
  background-color: var(--black); }

.toolbar--dark .toolbar__view-modes {
  border-left-color: var(--black); }

.toolbar--dark .toolbar__view-mode-separator {
  border-left-color: var(--black); }

.toolbar--dark .toolbar__button--more {
  background-color: var(--gray-800); }
  .toolbar--dark .toolbar__button--more.toolbar__button--active, .toolbar--dark .toolbar__button--more.toolbar__button:active {
    background-color: var(--gray-700); }
