/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.info-block {
  display: grid;
  grid-template-areas:
    '. title'
    'icon message';
  column-gap: calc(var(--spacing-unit) * 5);
  grid-template-columns: max-content auto;

  @include for-phone-only {
    column-gap: calc(var(--spacing-unit) * 3);
  }
}

.info-block__icon {
  grid-area: icon;
}

.info-block__title {
  grid-area: title;
  @include typography('alpha', 'medium');
  margin-bottom: calc(var(--spacing-unit) * 1);
}

.info-block__message {
  grid-area: message;
  max-width: 32em;
}

.info-block__action {
  margin-top: calc(var(--spacing-unit) * 3);
  width: max-content;
}
