/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: calc(var(--spacing-unit) * 7);
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-300);

  align-items: center;
  z-index: 100;
}

.header__left {
  grid-column: 2 / 4;
}

.header__logo {
  @include typography($type: 'wide', $weight: 'medium');
}

.header__aspect {
  @include typography($type: 'wide');
  color: var(--gray-700);

  &::before {
    content: ' / ';
    word-spacing: 0.3em;
    color: var(--gray-400);
  }
}

.header__center {
  grid-column: 4 / 12;
  text-align: center;
  color: var(--gray-700);

  @include for-phone-only {
    grid-column: 4 / -2;
    text-align: right;
  }
}

.header__product-info {
  margin: 0 auto;
  max-width: max-content;
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 1.5);
  transition: all 0.3s;
  color: var(--gray-700);
  display: grid;
  grid-template-columns: auto max-content;
  column-gap: calc(var(--spacing-unit) * 1);
  align-items: center;
  position: relative;

  @include typography('beta');

  @include for-phone-only {
    pointer-events: none;
    grid-template-columns: auto;
    margin-right: 0;
  }
}

.header__product-info__icon {
  @include for-phone-only {
    display: none;
  }
}

.header__product-info--active {
  background-color: var(--gray-200);
  color: var(--gray-800);
  cursor: default;
}

.header__product-info:focus {
  outline: none;
}

.keyboard-focus .header__product-info:focus {
  @include keyboard-focus;
}

.header__product-info-tooltip {
  position: absolute;
  top: calc(100% + 2px);
  right: 16px;
  transform: translateX(50%);
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
  text-align: left;
}

.header__product-info--active .header__product-info-tooltip {
  opacity: 1;
}

.header__product-description {
  @include typography('alpha');
  width: 280px;
}

.header__right {
  grid-column: 12 / -2;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include for-phone-only {
    display: none;
  }
}

.header__user-info {
  transition: all 0.3s;
  position: relative;

  height: calc(var(--spacing-unit) * 5);
  width: calc(var(--spacing-unit) * 5);
  background-color: var(--gray-200);
  border: 1px solid var(--gray-300);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__user-info--active {
  cursor: default;
}

.header__user-info:focus {
  outline: none;
}

.keyboard-focus .header__user-info:focus {
  @include keyboard-focus;
}

.header__user-info-tooltip {
  position: absolute;
  top: calc(100% + 2px);
  right: 50%;
  transform: translateX(calc(var(--spacing-unit) * 2));
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
}

.header__user-info--active .header__user-info-tooltip {
  opacity: 1;
  pointer-events: all;
}

.header__user-description {
  width: calc(var(--spacing-unit) * 50);
  margin-right: calc(var(--spacing-unit) * 2);
}

.header__user-description-intro {
  @include typography('alpha', 'medium');
  margin-bottom: calc(var(--spacing-unit) * 3);
}

.header__user-description-main {
  margin-top: calc(var(--spacing-unit) * 3);
  color: var(--gray-300);
}

.header__user-description-link {
  @include typography('alpha', 'regular');
  color: inherit;
  display: block;
  text-decoration-color: var(--gray-600);
}

.header__user-description-link:focus {
  outline: none;
}

.keyboard-focus .header__user-description-link:focus {
  @include keyboard-focus;
}

.header__user-description-link + .header__user-description-link {
  margin-top: calc(var(--spacing-unit) * 1);
}
