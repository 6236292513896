@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.contract-form-wrapper {
  background-color: var(--gray-900);
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(var(--spacing-unit) * 5); }

.contract-form {
  border-radius: var(--border-radius);
  background-color: var(--gray-200);
  overflow: hidden;
  height: 100%;
  max-width: calc(var(--spacing-unit) * 120);
  max-height: calc(var(--spacing-unit) * 110);
  display: grid;
  grid-template-rows: min-content auto min-content; }

.contract-form__intro {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-underline-offset: 3px;
  text-decoration-thickness: 500%;
  font-weight: 500;
  letter-spacing: -0.013em;
  margin: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4);
  max-width: 15em; }
  @media (max-width: 599px) {
    .contract-form__intro {
      font-size: 26px;
      line-height: 33px; } }
  .contract-form__intro strong {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-underline-offset: 3px;
    text-decoration-thickness: 500%;
    font-weight: 500;
    letter-spacing: -0.013em;
    color: var(--highlight-dark); }
    @media (max-width: 599px) {
      .contract-form__intro strong {
        font-size: 26px;
        line-height: 33px; } }

.contract-form__text {
  border: 1px solid var(--gray-300);
  background-color: var(--gray-100);
  padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4);
  overflow-y: auto;
  color: var(--gray-700);
  transition: color 0.4s; }
  .contract-form__text h1 {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 30px;
    line-height: 36px;
    text-underline-offset: 3px;
    font-weight: 500;
    letter-spacing: -0.004em;
    margin: 0;
    margin-bottom: calc(var(--spacing-unit) * 5); }
    @media (max-width: 599px) {
      .contract-form__text h1 {
        font-size: 22px;
        line-height: 28px; } }
  .contract-form__text h2 {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 22px;
    line-height: 30px;
    text-underline-offset: 2px;
    font-weight: 500;
    letter-spacing: 0.001em;
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 4);
    margin-bottom: calc(var(--spacing-unit) * 3); }
    @media (max-width: 599px) {
      .contract-form__text h2 {
        font-size: 20px;
        line-height: 28px; } }
  .contract-form__text p {
    margin: 0;
    margin-bottom: 0.8em;
    max-width: 38em; }
    .contract-form__text p:last-child {
      margin-bottom: 0; }
  .contract-form__text li {
    max-width: 38em; }
  .contract-form__text strong {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-underline-offset: 2px;
    font-weight: 500;
    letter-spacing: 0.016em; }

.contact-form--scrolled .contract-form__text {
  color: var(--black); }

.contract-form__footer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2);
  padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4);
  background-color: var(--highlight);
  color: var(--white);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em; }
