$spacing: (
  spacing-unit: 6px,
  border-radius: 3px,
);

$grayscale-raw: (
  'white': hsl(0, 0%, 100%),
  gray-100: hsl(230, 21.3%, 98%),
  gray-150: hsl(230, 20%, 95%),
  gray-200: hsl(230, 18.4%, 92%),
  gray-300: hsl(230, 15%, 83%),
  gray-400: hsl(230, 12%, 75%),
  gray-500: hsl(230, 10%, 65%),
  gray-600: hsl(230, 8%, 52%),
  gray-700: hsl(230, 7.7%, 40%),
  gray-800: hsl(230, 9%, 28%),
  gray-900: hsl(230, 12%, 14%),
  gray-950: hsl(230, 14%, 10%),
  'black': hsl(0, 0%, 0%),
);

$grayscale: (
  'white': #ffffff,
  gray-100: #f9f9fb,
  gray-150: #f0f1f5,
  gray-200: #e8e9ed,
  gray-300: #cdcfda,
  gray-400: #b8bac7,
  gray-500: #9da0af,
  gray-600: #7b7e8e,
  gray-700: #5e616e,
  gray-800: #41434e,
  gray-900: #1f2128,
  gray-950: #16171d,
  'black': #000000,
);

//background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(24,24,32,1) 11%, rgba(31,31,40,1) 14%, rgba(55,55,67,1) 24%, rgba(89,89,105,1) 38%, rgba(116,116,134,1) 49%, rgba(158,158,174,1) 65%, rgba(195,195,208,1) 79%, rgba(217,217,227,1) 87%, rgba(231,231,238,1) 92%, rgba(243,243,247,1) 96%, rgba(249,249,251,1) 98%, rgba(255,255,255,1) 100%);

$primary: (
  highlight-light: #5588ff,
  highlight: #2560eb,
  highlight-dark: #1143b8,
  highlight-background: #dee5f7,
  stop: #ff5c57,
  stop-dark: #cc3d33,
  caution: #ffe657,
  caution-dark: #837421,
  go: #64dd7e,
  go-dark: #328644,
  keyboard-focus: #f4da7e,
  meta: #e100e5,
  support: #ffaca1,
  support-dark: #c78379,
  marketing: #f6ff51,
  admin: #d90797,
  admin-dark: #940b69,
);

$secondary: (
  lemon-tarte: #fff6aa,
  lemon-tarte-dark: #9e9651,
  lemon-tarte-bright: #fff17c,
  peach-crumble: #f9d6aa,
  peach-crumble-dark: #af895a,
  peach-crumble-bright: #f5be79,
  lime-pie: #7de1cd,
  lime-pie-dark: #2a9d86,
  lime-pie-bright: #00ffcc,
  blueberry-pancakes: #1599c1,
  blueberry-pancakes-dark: #18647b,
  blueberry-pancakes-bright: #0ac1f9,
  plum-compote: #263b6b,
  plum-compote-dark: #1a2338,
  plum-compote-bright: #1d3d85,
  raspberry-sorbet: #fc9c9c,
  raspberry-sorbet-dark: #bd5f62,
  raspberry-sorbet-bright: #ff585e,
);

$brand: (
  red: #ff2c00,
  green: #00dbc2,
  green-dark: #00ad99,
);

@mixin keyboard-focus($position: 'outside', $dark: 'false') {
  --focus-color: var(--keyboard-focus);

  @if $dark == 'true' {
    --focus-color: #{darken(map-get($primary, 'keyboard-focus'), 15)};
  }

  @if $position == 'outside' {
    box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color);
  }

  @if $position == 'inside' {
    box-shadow: inset 0 0 0 3px var(--focus-color), 0 0 6px 0 var(--focus-color);
  }
}

.keyboard-focus-demo {
  @include keyboard-focus;
}

@mixin pointer-focus {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px transparentize(map-get($primary, 'highlight'), 0.68),
    0 0 9px 0 transparentize(map-get($primary, 'highlight'), 0.68);
}

.pointer-focus-demo {
  @include pointer-focus;
}

@mixin invalid {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px lighten(map-get($primary, 'stop'), 20), 0 0 9px 0 lighten(map-get($primary, 'stop'), 20);
}

.invalid-demo {
  @include invalid;
}

@mixin shadow-card($light: false) {
  @if $light == false {
    box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3);
  } @else {
    box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.15);
  }
}

@mixin shadow-bar {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4);
}

@mixin shadow-floating($light: false) {
  @if $light == false {
    box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4);
  } @else {
    box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.2);
  }
}

@mixin shadow-draggable($isDragging: false) {
  @if $isDragging == false {
    box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08);
  } @else {
    box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12);
  }
}

.shadow-card-demo {
  @include shadow-card;
}
.shadow-bar-demo {
  @include shadow-bar;
}
.shadow-floating-demo {
  @include shadow-floating;
}
.shadow-draggable-demo {
  @include shadow-draggable;
}
.shadow-draggable--dragging-demo {
  @include shadow-draggable(true);
}

@mixin button-reset {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
}

$font-family-default: 'Söhne', Helvetica, Chalkboard, -apple-system, sans-serif;
$font-family-mono: 'Söhne Mono', monospace;
$font-family-wide: 'Söhne Breit', monospace;
$font-family-serif: 'Charter', serif;

@mixin webfonts {
  @font-face {
    font-family: 'Söhne';
    src: url('"https://fonts.fieldbyfield.com"/soehne-web-leicht.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-web-leichte.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-web-leicht.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-web-leicht.woff') format('woff');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Söhne';
    src: url('"https://fonts.fieldbyfield.com"/soehne-web-buch.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-web-buch/filename.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-web-buch.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-web-buch.woff') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Söhne';
    src: url('"https://fonts.fieldbyfield.com"/soehne-web-kraftig.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-web-kraftig.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-web-kraftig.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-web-kraftig.woff') format('woff');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Söhne Breit';
    src: url('"https://fonts.fieldbyfield.com"/soehne-breit-web-buch.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-breit-web-buch.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-breit-web-buch.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-breit-web-buch.woff') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Söhne Breit';
    src: url('"https://fonts.fieldbyfield.com"/soehne-breit-web-kraftig.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-breit-web-kraftig.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-breit-web-kraftig.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-breit-web-kraftig.woff') format('woff');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Söhne Breit';
    src: url('"https://fonts.fieldbyfield.com"/soehne-breit-web-halbfett.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-breit-web-halbfett.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-breit-web-halbfett.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-breit-web-halbfett.woff') format('woff');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Söhne Mono';
    src: url('"https://fonts.fieldbyfield.com"/soehne-mono-web-buch.eot');
    src: url('"https://fonts.fieldbyfield.com"/soehne-mono-web-buch.eot?#iefix') format('embedded-opentype'),
      url('"https://fonts.fieldbyfield.com"/soehne-mono-web-buch.woff2') format('woff2'),
      url('"https://fonts.fieldbyfield.com"/soehne-mono-web-buch.woff') format('woff');
    font-weight: 400;
  }
}

@mixin typography($size: 'beta', $weight: 'regular', $type: 'default') {
  text-rendering: optimizeLegibility;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;

  @if $type == 'default' {
    font-family: $font-family-default;

    @if $size == 'tiny' {
      font-size: 11px;
      line-height: 15px;
      text-underline-offset: 1px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: 0.03em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: 0.03em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: 0.04em;
      }
    }

    @if $size == 'alpha' {
      font-size: 13px;
      line-height: 18px;
      text-underline-offset: 1.5px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: 0.02em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: 0.02em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: 0.035em;
      }
    }

    @if $size == 'beta' {
      font-size: 16px;
      line-height: 24px;
      text-underline-offset: 2px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: 0.009em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: 0.009em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: 0.016em;
      }
    }

    @if $size == 'gamma' {
      font-size: 22px;
      line-height: 30px;
      text-underline-offset: 2px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.005em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.005em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: 0.001em;
      }

      @include for-phone-only {
        font-size: 20px;
        line-height: 28px;
      }
    }

    @if $size == 'delta' {
      font-size: 30px;
      line-height: 36px;
      text-underline-offset: 3px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.01em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.01em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: -0.004em;
      }

      @include for-phone-only {
        font-size: 22px;
        line-height: 28px;
      }
    }

    @if $size == 'epsilon' {
      font-size: 36px;
      line-height: 42px;
      text-underline-offset: 3px;
      text-decoration-thickness: 500%;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.015em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.015em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: -0.013em;
      }

      @include for-phone-only {
        font-size: 26px;
        line-height: 33px;
      }
    }

    @if $size == 'zeta' {
      font-size: 44px;
      line-height: 48px;
      text-underline-offset: 4px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.02em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.017em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: -0.015em;
      }

      @include for-phone-only {
        font-size: 32px;
        line-height: 35px;
      }
    }

    @if $size == 'eta' {
      font-size: 62px;
      line-height: 66px;
      text-underline-offset: 4px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.03em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.03em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: -0.016em;
      }

      @include for-tablet-portrait-down {
        font-size: 51px;
        line-height: 54px;
      }

      @include for-phone-only {
        font-size: 36px;
        line-height: 42px;
      }
    }

    @if $size == 'theta' {
      font-size: 74px;
      line-height: 76px;
      text-underline-offset: 5px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.03em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.03em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: -0.022em;
      }

      @include for-tablet-portrait-down {
        font-size: 63px;
        line-height: 60px;
      }

      @include for-phone-only {
        font-size: 51px;
        line-height: 54px;
      }
    }
  }

  @if $type == 'wide' {
    font-family: $font-family-wide;

    @if $size == 'alpha' and $weight == 'regular' {
      font-weight: 400;
      font-size: 11.5px;
      line-height: 18px;
      letter-spacing: 0.05em;
    }

    @if $size == 'alpha' and $weight == 'medium' {
      font-weight: 500;
      font-size: 11.5px;
      line-height: 18px;
      letter-spacing: 0.06em;
    }

    @if $size == 'alpha' and $weight == 'bold' {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.036em;
    }

    @if $size == 'beta' and $weight == 'regular' {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }

    @if $size == 'beta' and $weight == 'medium' {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
    }

    @if $size == 'beta' and $weight == 'bold' {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }

    @if $size == 'gamma' and $weight == 'regular' {
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
    }

    @if $size == 'gamma' and $weight == 'medium' {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.004em;
    }

    @if $size == 'gamma' and $weight == 'bold' {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.005em;
    }

    @if $size == 'delta' and $weight == 'regular' {
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.01em;
    }

    @if $size == 'delta' and $weight == 'medium' {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.002em;
    }

    @if $size == 'delta' and $weight == 'bold' {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.004em;
    }

    @if $size == 'epsilon' and $weight == 'regular' {
      font-weight: 400;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.015em;
    }

    @if $size == 'epsilon' and $weight == 'medium' {
      font-weight: 500;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.01em;
    }

    @if $size == 'epsilon' and $weight == 'bold' {
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.013em;
    }

    @if $size == 'zeta' and $weight == 'regular' {
      font-weight: 400;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.02em;
    }

    @if $size == 'zeta' and $weight == 'medium' {
      font-weight: 500;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.013em;
    }

    @if $size == 'zeta' and $weight == 'bold' {
      font-weight: 600;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.016em;
    }

    @if $size == 'eta' and $weight == 'regular' {
      font-weight: 400;
      font-size: 62px;
      line-height: 66px;
      letter-spacing: -0.024em;
    }

    @if $size == 'eta' and $weight == 'medium' {
      font-weight: 500;
      font-size: 62px;
      line-height: 66px;
      letter-spacing: -0.022em;
    }

    @if $size == 'eta' and $weight == 'bold' {
      font-weight: 600;
      font-size: 62px;
      line-height: 66px;
      letter-spacing: -0.022em;
    }

    @if $size == 'theta' {
      font-size: 74px;
      line-height: 76px;

      @if $weight == 'light' {
        font-weight: 300;
        letter-spacing: -0.03em;
      }

      @if $weight == 'regular' {
        font-weight: 400;
        letter-spacing: -0.03em;
      }

      @if $weight == 'medium' {
        font-weight: 500;
        letter-spacing: -0.022em;
      }
    }
  }

  @if $type == 'serif' {
    font-family: $font-family-serif;
  }

  @if $type == 'mono' {
    font-family: $font-family-mono;

    @if $size == 'alpha' {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
    }

    @if $size == 'beta' {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.009em;
    }

    @if $size == 'gamma' {
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.005em;
    }

    @if $size == 'delta' {
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.01em;
    }

    @if $size == 'epsilon' {
      font-weight: 400;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.015em;
    }

    @if $size == 'zeta' {
      font-weight: 400;
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em;
    }

    @if $size == 'eta' {
      font-weight: 400;
      font-size: 62px;
      line-height: 66px;
      letter-spacing: -0.03em;
    }

    @if $size == 'theta' {
      font-size: 74px;
      line-height: 76px;
      font-weight: 400;
      letter-spacing: -0.03em;
    }
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-down {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin non-retina-only {
  @media not screen and (-webkit-min-device-pixel-ratio: 2),
    not screen and (min--moz-device-pixel-ratio: 2),
    not screen and (-o-min-device-pixel-ratio: 2/1),
    not screen and (min-device-pixel-ratio: 2),
    not screen and (min-resolution: 192dpi),
    not screen and (min-resolution: 2dppx) {
    @content;
  }
}
