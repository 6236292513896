/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.cue-type-icon {
  width: auto;
  display: block;
  overflow: visible; }
  .cue-type-icon > * {
    vector-effect: non-scaling-stroke; }

.cue-type-icon--fill {
  height: 100%;
  width: 100%; }

.cue-type-icon--tiny {
  height: 12px; }

.cue-type-icon--alpha {
  height: 16px; }

.cue-type-icon--beta {
  height: 24px; }

.cue-type-icon--gamma {
  height: 36px; }

.cue-type-icon--delta {
  height: 48px; }

.cue-type-icon--default {
  fill: var(--lime-pie-bright); }

.cue-type-icon--question {
  fill: var(--plum-compote-bright); }

.cue-type-icon--product,
.cue-type-icon--product-unlinked {
  fill: var(--peach-crumble-bright); }

.cue-type-icon--person {
  fill: var(--lemon-tarte-bright); }

.cue-type-icon--action-item {
  fill: var(--raspberry-sorbet-bright); }

.cue-type-icon--group {
  fill: #b8bac7; }

.cue-type-icon--group-dark {
  fill: #656981; }

.cue-type-icon--group-very-dark {
  fill: #3a3c46; }

.cue-type-icon--normal.cue-type-icon--default {
  fill: var(--lime-pie-bright);
  stroke: #00d1a7; }

.cue-type-icon--normal.cue-type-icon--question {
  fill: var(--plum-compote-bright);
  stroke: var(--plum-compote-dark);
  stroke: black; }

.cue-type-icon--normal.cue-type-icon--product, .cue-type-icon--normal.cue-type-icon--product-unlinked {
  fill: var(--peach-crumble-bright);
  stroke: var(--peach-crumble-dark);
  stroke: #de973e; }

.cue-type-icon--normal.cue-type-icon--person {
  fill: var(--lemon-tarte-bright);
  stroke: var(--lemon-tarte-dark);
  stroke: #dfcb21; }

.cue-type-icon--normal.cue-type-icon--action-item {
  fill: var(--raspberry-sorbet-bright);
  stroke: var(--raspberry-sorbet-dark);
  stroke: #9d2126; }

.cue-type-icon--normal.cue-type-icon--group {
  fill: var(--gray-200);
  stroke: var(--gray-400); }

.cue-type-icon--white.cue-type-icon--default {
  fill: var(--white);
  stroke: var(--gray-400); }

.cue-type-icon--white.cue-type-icon--question {
  fill: var(--white);
  stroke: var(--gray-400); }

.cue-type-icon--white.cue-type-icon--product, .cue-type-icon--white.cue-type-icon--product-unlinked {
  fill: var(--white);
  stroke: var(--gray-400); }

.cue-type-icon--white.cue-type-icon--person {
  fill: var(--white);
  stroke: var(--gray-400); }

.cue-type-icon--white.cue-type-icon--action-item {
  fill: var(--white);
  stroke: var(--gray-400); }

.cue-type-icon--white.cue-type-icon--group {
  fill: var(--white);
  stroke: var(--gray-400); }
