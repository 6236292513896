@charset "UTF-8";
/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.user-role {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 1.5); }

.user-role__avatar {
  height: auto; }

.user-role__description {
  overflow: hidden; }

.user-role__role {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: var(--gray-600);
  overflow: hidden;
  text-overflow: ellipsis; }

.user-role__name {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--gray-800); }

.user-role--size-beta .user-role__role {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em; }

.user-role--size-beta .user-role__name {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em; }

.user-role--theme-light .user-role__role {
  color: var(--gray-400); }

.user-role--theme-light .user-role__name {
  color: var(--gray-200); }
