/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.dropdown {
  border-radius: var(--border-radius);
  background-color: var(--gray-100);
  position: relative;
  border: 1px solid var(--gray-300);
  transition: background-color 0.2s;
  height: calc(var(--spacing-unit) * 6); }

.dropdown::before {
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: -1px;
  transition: border 0.1s;
  content: '';
  border: 2px solid transparent;
  border-radius: var(--border-radius);
  pointer-events: none; }

.dropdown__select {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  display: block;
  width: 100%;
  border: none;
  border-radius: var(--border-radius);
  height: calc((var(--spacing-unit) * 6) - 2px);
  padding: 0 calc(var(--spacing-unit) * 8) 0 calc(var(--spacing-unit) * 2);
  background-color: transparent;
  appearance: none;
  outline: none;
  text-overflow: ellipsis; }
  .dropdown__select option {
    color: black; }

.dropdown__icon {
  position: absolute;
  top: calc(var(--spacing-unit) * 1.5);
  right: calc(var(--spacing-unit) * 2);
  pointer-events: none;
  color: var(--black); }

.dropdown__select:invalid {
  color: var(--gray-500); }

.dropdown--disabled {
  opacity: 0.3;
  cursor: default; }

.dropdown--invalid::before {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.dropdown--invalid:focus-within::before {
  border: 1px solid var(--stop); }

.dropdown__select:focus {
  outline: none; }

.keyboard-focus .dropdown:focus-within::before {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.dropdown--light {
  background-color: var(--white); }
