@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.selection-bar {
  margin-top: calc(var(--spacing-unit) * 1);
  margin-bottom: calc(var(--spacing-unit) * 1);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius);
  position: relative; }

.selection-bar::after {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: -1px;
  border: 1px solid var(--stop);
  border-radius: var(--border-radius);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd;
  pointer-events: none;
  opacity: 0; }

.selection-bar--invalid::after {
  opacity: 1; }

.selection-bar-button {
  display: block;
  cursor: pointer;
  position: relative; }

.selection-bar-button:first-child .selection-bar-button__label {
  border-top-left-radius: calc(var(--border-radius) - 1px);
  border-bottom-left-radius: calc(var(--border-radius) - 1px); }

.selection-bar-button:last-child .selection-bar-button__label {
  border-top-right-radius: calc(var(--border-radius) - 1px);
  border-bottom-right-radius: calc(var(--border-radius) - 1px); }

.selection-bar-button + .selection-bar-button {
  border-left: 1px solid var(--gray-300); }

.selection-bar-button__input {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  float: left; }

.selection-bar-button__label {
  background-color: var(--gray-100);
  color: var(--gray-700);
  width: 100%;
  height: 100%;
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 4);
  text-align: center;
  transition: all 0.2s;
  white-space: nowrap; }

.selection-bar-button__input:checked ~ .selection-bar-button__label {
  background-color: var(--highlight);
  color: var(--white); }

.keyboard-focus .selection-bar-button__input:focus ~ .selection-bar-button__label {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color);
  z-index: 2;
  position: relative; }

.selection-bar--disabled {
  opacity: 0.3; }

.selection-bar--disabled .selection-bar-button {
  cursor: default; }

.selection-bar--disabled .selection-bar-button__input:checked ~ .selection-bar-button__label {
  background-color: var(--gray-600); }

.selection-bar--light .selection-bar-button__label {
  background-color: var(--white); }

.selection-bar--small {
  border: none;
  background-color: var(--gray-300);
  padding: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: calc(var(--border-radius) * 2); }
  .selection-bar--small .selection-bar-button + .selection-bar-button {
    border-left: none; }

.selection-bar--small .selection-bar-button__label {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  background-color: transparent;
  color: var(--gray-800);
  padding: calc(var(--spacing-unit) * 0.3) calc(var(--spacing-unit) * 1.5);
  border-radius: calc((var(--border-radius) * 2) - 2px); }

.selection-bar--small .selection-bar-button:first-child .selection-bar-button__label {
  border-top-left-radius: calc((var(--border-radius) * 2) - 2px);
  border-bottom-left-radius: calc((var(--border-radius) * 2) - 2px); }

.selection-bar--small .selection-bar-button:last-child .selection-bar-button__label {
  border-top-right-radius: calc((var(--border-radius) * 2) - 2px);
  border-bottom-right-radius: calc((var(--border-radius) * 2) - 2px); }

.selection-bar--small .selection-bar-button__input:checked ~ .selection-bar-button__label {
  background-color: var(--white);
  color: var(--black);
  box-shadow: 0 3px 10px -7px rgba(0, 0, 0, 0.6); }

.keyboard-focus .selection-bar--small .selection-bar-button__input:focus ~ .selection-bar-button__label {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color);
  z-index: 1; }

.selection-bar--small.selection-bar--dark {
  background-color: var(--gray-900); }
  .selection-bar--small.selection-bar--dark .selection-bar-button__label {
    color: var(--gray-500); }
  .selection-bar--small.selection-bar--dark .selection-bar-button__input:checked ~ .selection-bar-button__label {
    background-color: var(--gray-600);
    color: var(--white); }
