/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.introduction__intro {
  grid-column: 2 / 10;
  grid-row: 1;
  padding-top: calc(var(--spacing-unit) * 16);
  padding-bottom: calc(var(--spacing-unit) * 12);
  padding-right: calc(var(--spacing-unit) * 10);

  @include for-tablet-landscape-down {
    grid-column: 2 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 2 / -2;
    padding-right: calc(var(--spacing-unit) * 0);
  }
}

.introduction__intro-title {
  @include typography('eta', $weight: 'medium');
  margin: 0;
  margin-bottom: calc(var(--spacing-unit) * 8);
}

.introduction__intro-text {
  color: var(--gray-700);
  hyphens: auto;
}

.introduction__product-info {
  grid-row: 2;
  grid-column: 2 / 8;
  margin-bottom: calc(var(--spacing-unit) * 9);

  border: 1px solid var(--gray-400);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3);

  @include for-tablet-landscape-down {
    grid-column: 2 / 11;
  }

  @include for-tablet-portrait-down {
    grid-column: 2 / -2;
  }
}

.introduction__product-info-title {
  @include typography('alpha', $weight: 'medium');
  color: var(--gray-700);
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  margin-top: calc(var(--spacing-unit) * -2 - 0.75em);
  background-color: white;
  width: max-content;
  padding: 0 calc(var(--spacing-unit) * 1);
  margin-left: calc(var(--spacing-unit) * -1);
}

.introduction__product-info-name {
  @include typography($weight: 'medium');
}

.introduction__info {
  grid-row: 4;
  grid-column: 2 / 8;

  padding-right: calc(var(--spacing-unit) * 10);
  padding-top: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 18);

  display: grid;
  row-gap: calc(var(--spacing-unit) * 6);

  @include for-tablet-landscape-down {
    grid-column: 2 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 2 / -2;
    padding-right: calc(var(--spacing-unit) * 0);
  }
}

.introduction__info-background {
  grid-row: 4;
  grid-column: 1 / 10;

  @include for-tablet-landscape-down {
    grid-column: 1 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 1 / -1;
  }
}

.introduction__timebox {
  @include for-phone-only {
    display: none;
  }
}

.introduction-user-info {
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 0.25) calc(var(--spacing-unit) * 0.8);
  transition: all 0.3s;
  background-color: var(--gray-200);

  display: inline-flex;
  align-items: center;
  position: relative;

  @include for-tablet-landscape-down {
    pointer-events: none;
    background-color: inherit;
    padding: 0;
  }
}

.introduction-user-info:focus {
  outline: none;
}

.keyboard-focus .introduction-user-info:focus {
  @include keyboard-focus;
}

.introduction-user-info__icon {
  margin-left: calc(var(--spacing-unit) * 1);

  @include for-tablet-landscape-down {
    display: none;
  }
}

.introduction-user-info__tooltip {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(calc(var(--spacing-unit) * 0.5 + 100%), calc(var(--spacing-unit) * -2));
  display: none;
}

.introduction-user-info:hover,
.introduction-user-info:focus {
  background-color: var(--gray-300);

  .introduction-user-info__tooltip {
    display: block;
  }
}

.introduction-user-info__table {
  margin-top: calc(var(--spacing-unit) * 2);
}

.introduction-user-info-mobile {
  border-radius: var(--border-radius);
  background-color: var(--gray-800);
  color: var(--white);
  border: 1px solid var(--gray-900);
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2);

  margin-top: calc(var(--spacing-unit) * 3);

  display: none;

  @include for-tablet-landscape-down {
    display: block;
  }
}

.introduction__get-started {
  grid-row: 3;
  grid-column: 2 / 10;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 3);

  padding-top: calc(var(--spacing-unit) * 12);
  padding-right: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 12);
  overflow: hidden;

  @include for-tablet-landscape-down {
    grid-column: 2 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 2 / -1;
    padding-right: calc(var(--spacing-unit) * 5);
  }

  @include for-phone-only {
    grid-template-columns: auto;
    grid-template-rows: auto calc(var(--spacing-unit) * 6) auto;
    row-gap: calc(var(--spacing-unit) * 2);
    justify-items: center;
    color: var(--white);
    grid-column: 1 / -1;
    padding-right: calc(var(--spacing-unit) * 3);
    padding-left: calc(var(--spacing-unit) * 3);
  }
}

.introduction__get-started-background {
  background-color: var(--gray-150);
  position: relative;

  grid-row: 3;
  grid-column: 1 / -1;
  z-index: -2;

  .introduction__get-started-key-visual {
    display: none;
  }

  @include for-phone-only {
    background-color: var(--gray-900);

    .introduction__get-started-key-visual {
      display: block;
      filter: invert(1);
      opacity: 0.05;
    }
  }
}

.introduction__get-started-text {
  @include typography('delta');
}

.introduction__get-started-line {
  border-bottom: 1px solid var(--gray-400);

  @include for-phone-only {
    height: 100%;
    border-left: 1px solid var(--black);
    border-bottom: none;
  }
}

.introduction__get-started-button {
  position: relative;
}

.introduction__get-started-radar {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
}
