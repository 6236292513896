/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.meta-data-table {
  color: var(--gray-400);
  @include typography($size: 'alpha');
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: calc(var(--spacing-unit) * 1.5);
  row-gap: calc(var(--spacing-unit) * 1);
  align-items: center;
}
