@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.canvas {
  width: 100%;
  height: 100%;
  display: block;
  --label-color: var(--gray-900);
  --frame-line-color: var(--gray-700);
  --frame-circle-color: var(--gray-600);
  --dotgrid-color: var(--gray-900);
  --fill-color: transparent;
  --hatch-color: var(--gray-300);
  --venn-line-color: var(--gray-600);
  --venn-label-color: var(--gray-400);
  --venn-fill-color: transparent;
  --highlight-color: var(--white);
  --highlight-label-color: var(--highlight-color);
  --highlight-frame-color: var(--highlight-color);
  --highlight-dotgrid-color: var(--highlight-color);
  --highlight-fill-color: transparent;
  --highlight-venn-color: rgba(255, 255, 255, 0.1); }

.canvas__line {
  stroke-linecap: square;
  stroke-width: 0.5px;
  fill: none;
  stroke: var(--frame-line-color);
  transition: all 0.5s; }

.canvas__line--dashed {
  stroke-dasharray: 2.7 2.7;
  stroke-dashoffset: 2.7;
  stroke-linecap: butt; }

.canvas__line--highlight-only {
  opacity: 0; }

.canvas__circle {
  stroke-width: 0;
  fill: var(--frame-circle-color);
  transition: fill 0.5s; }

.canvas__fill {
  fill: var(--fill-color); }

.canvas__hatch-pattern {
  stroke-width: 0.25px;
  stroke: var(--hatch-color); }

.canvas__label {
  stroke-width: 0;
  fill: var(--label-color);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne Breit", monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  font-size: 3.2px;
  letter-spacing: 0.05em;
  line-height: 20px;
  dominant-baseline: middle;
  transition: all 0.4s; }

.canvas__label--right {
  text-anchor: end; }

.canvas__label--bottom,
.canvas__label--bottom tspan {
  dominant-baseline: hanging; }

.canvas__label--top,
.canvas__label--top tspan {
  dominant-baseline: auto; }

.canvas__label--all-caps {
  letter-spacing: 0.1em;
  font-size: 2.9px; }

.canvas__label--element {
  font-size: 2.9px;
  letter-spacing: 0.5em; }

.canvas__dotgrid-dot {
  stroke: none;
  fill: var(--dotgrid-color);
  transition: all 0.5s; }

.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas__dotgrid-dot--over-line {
  opacity: 0; }

.canvas__dotgrid-dot--over-product {
  opacity: 0; }

/* Label Modes */
.canvas--labels-prominent {
  --label-color: var(--gray-300); }

.canvas--labels-hidden .canvas__label,
.canvas--labels-show-explicit .canvas__label {
  opacity: 0; }

.canvas--labels-hidden .canvas__dotgrid-dot--over-label,
.canvas--labels-show-explicit .canvas__dotgrid-dot--over-label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-value .canvas__value.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-value .canvas__value.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-value-element .canvas__value-element.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-value-element .canvas__value-element.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-problem .canvas__problem.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-problem .canvas__problem.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-motivations .canvas__motivations.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-motivations .canvas__motivations.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-users .canvas__users.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-users .canvas__users.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-market .canvas__market.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-market .canvas__market.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-market-element .canvas__market-element.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-market-element .canvas__market-element.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-customers .canvas__customers.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-customers .canvas__customers.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-distribution .canvas__distribution.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-distribution .canvas__distribution.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-alternatives .canvas__alternatives.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-alternatives .canvas__alternatives.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-resources .canvas__resources.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-resources .canvas__resources.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-resources-element .canvas__resources-element.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-resources-element .canvas__resources-element.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-production .canvas__production.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-production .canvas__production.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-solution .canvas__solution.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-solution .canvas__solution.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-enablers .canvas__enablers.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-enablers .canvas__enablers.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-idea .canvas__idea.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-idea .canvas__idea.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-idea-element .canvas__idea-element.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-idea-element .canvas__idea-element.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-drivers .canvas__drivers.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-drivers .canvas__drivers.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-goals .canvas__goals.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-goals .canvas__goals.canvas__dotgrid-dot--over-label {
  opacity: 0; }

.canvas--labels-show-explicit.canvas--labels-show-uniqueness .canvas__uniqueness.canvas__label {
  opacity: 1; }

.canvas--labels-show-explicit.canvas--labels-show-uniqueness .canvas__uniqueness.canvas__dotgrid-dot--over-label {
  opacity: 0; }

/* Frame Modes */
.canvas--frame-prominent {
  --frame-line-color: var(--gray-400);
  --frame-circle-color: var(--gray-200); }

.canvas--frame-hidden .canvas__line:not(.canvas__product):not(.canvas__line--corner) {
  opacity: 0; }

.canvas--frame-hidden .canvas__dotgrid-dot--over-line {
  opacity: 1; }

/* Highlight */
.canvas__activities {
  stroke-dasharray: 330;
  stroke-dashoffset: 660;
  animation: dash 1s linear forwards infinite; }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

.canvas--highlight-labels .canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-marker .canvas__marker.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-marker .canvas__marker.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-marker .canvas__marker.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-marker .canvas__marker.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-marker .canvas__marker.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-marker .canvas__marker.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-marker .canvas__marker.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-elements .canvas__elements.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-elements .canvas__elements.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-elements .canvas__elements.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-elements .canvas__elements.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-elements .canvas__elements.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-elements .canvas__elements.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-elements .canvas__elements.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-introduction .canvas__introduction.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-introduction .canvas__introduction.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-introduction .canvas__introduction.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-introduction .canvas__introduction.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-introduction .canvas__introduction.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-introduction .canvas__introduction.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-introduction .canvas__introduction.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-realization .canvas__realization.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-realization .canvas__realization.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-realization .canvas__realization.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-realization .canvas__realization.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-realization .canvas__realization.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-realization .canvas__realization.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-realization .canvas__realization.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-activities .canvas__realization.canvas__line,
.canvas--highlight-activities .canvas__introduction.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-activities .canvas__realization.canvas__line--highlight-only,
.canvas--highlight-activities .canvas__introduction.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-activities .canvas__realization.canvas__circle,
.canvas--highlight-activities .canvas__introduction.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-activities .canvas__realization.canvas__label,
.canvas--highlight-activities .canvas__introduction.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-activities .canvas__realization.canvas__dotgrid-dot,
.canvas--highlight-activities .canvas__introduction.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-activities .canvas__realization.canvas__fill,
.canvas--highlight-activities .canvas__introduction.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-activities .canvas__realization.canvas__venn-circle,
.canvas--highlight-activities .canvas__introduction.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-product .canvas__product.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-product .canvas__product.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-product .canvas__product.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-product .canvas__product.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-product .canvas__product.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-product .canvas__product.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-product .canvas__product.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-core .canvas__core.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-core .canvas__core.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-core .canvas__core.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-core .canvas__core.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-core .canvas__core.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-core .canvas__core.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-core .canvas__core.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-context .canvas__context.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-context .canvas__context.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-context .canvas__context.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-context .canvas__context.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-context .canvas__context.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-context .canvas__context.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-context .canvas__context.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-idea .canvas__idea.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-idea .canvas__idea.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-idea .canvas__idea.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-idea .canvas__idea.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-idea .canvas__idea.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-idea .canvas__idea.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-idea .canvas__idea.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-market .canvas__market.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-market .canvas__market.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-market .canvas__market.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-market .canvas__market.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-market .canvas__market.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-market .canvas__market.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-market .canvas__market.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-resources .canvas__resources.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-resources .canvas__resources.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-resources .canvas__resources.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-resources .canvas__resources.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-resources .canvas__resources.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-resources .canvas__resources.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-resources .canvas__resources.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-value .canvas__value.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-value .canvas__value.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-value .canvas__value.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-value .canvas__value.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-value .canvas__value.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-value .canvas__value.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-value .canvas__value.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-drivers .canvas__drivers.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-goals .canvas__goals.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-goals .canvas__goals.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-goals .canvas__goals.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-goals .canvas__goals.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-goals .canvas__goals.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-goals .canvas__goals.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-goals .canvas__goals.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-uniqueness .canvas__uniqueness.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-drivers .canvas__drivers.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-drivers .canvas__drivers.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-production .canvas__production.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-production .canvas__production.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-production .canvas__production.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-production .canvas__production.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-production .canvas__production.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-production .canvas__production.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-production .canvas__production.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-enablers .canvas__enablers.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-enablers .canvas__enablers.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-enablers .canvas__enablers.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-enablers .canvas__enablers.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-enablers .canvas__enablers.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-enablers .canvas__enablers.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-enablers .canvas__enablers.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-solution .canvas__solution.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-solution .canvas__solution.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-solution .canvas__solution.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-solution .canvas__solution.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-solution .canvas__solution.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-solution .canvas__solution.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-solution .canvas__solution.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-alternatives .canvas__alternatives.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-alternatives .canvas__alternatives.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-alternatives .canvas__alternatives.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-alternatives .canvas__alternatives.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-alternatives .canvas__alternatives.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-alternatives .canvas__alternatives.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-alternatives .canvas__alternatives.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-distribution .canvas__distribution.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-distribution .canvas__distribution.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-distribution .canvas__distribution.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-distribution .canvas__distribution.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-distribution .canvas__distribution.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-distribution .canvas__distribution.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-distribution .canvas__distribution.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-customers .canvas__customers.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-customers .canvas__customers.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-customers .canvas__customers.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-customers .canvas__customers.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-customers .canvas__customers.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-customers .canvas__customers.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-customers .canvas__customers.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-problem .canvas__problem.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-problem .canvas__problem.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-problem .canvas__problem.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-problem .canvas__problem.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-problem .canvas__problem.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-problem .canvas__problem.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-problem .canvas__problem.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-users .canvas__users.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-users .canvas__users.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-users .canvas__users.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-users .canvas__users.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-users .canvas__users.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-users .canvas__users.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-users .canvas__users.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-motivations .canvas__motivations.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-motivations .canvas__motivations.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-motivations .canvas__motivations.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-motivations .canvas__motivations.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-motivations .canvas__motivations.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-motivations .canvas__motivations.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-motivations .canvas__motivations.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-desirability .canvas__venn-circle--desirability.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-viability .canvas__venn-circle--viability.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-feasibility .canvas__venn-circle--feasibility.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__line {
  stroke: var(--highlight-frame-color); }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__line--highlight-only {
  opacity: 1; }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__circle {
  fill: var(--highlight-frame-color); }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__label {
  fill: var(--highlight-label-color); }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__dotgrid-dot {
  fill: var(--highlight-dotgrid-color); }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__fill {
  fill: var(--highlight-fill-color); }

.canvas--highlight-marketability .canvas__venn-circle--marketability.canvas__venn-circle {
  fill: var(--highlight-venn-color); }

.canvas--venn-prominent {
  --venn-line-color: var(--gray-300);
  --venn-label-color: var(--white);
  --venn-fill-color: rgba(255, 255, 255, 0.1);
  --highlight-venn-color: rgba(255, 255, 255, 0.4); }

.canvas--venn-prominent .canvas__line--under-venn,
.canvas--venn-subtle .canvas__line--under-venn {
  display: none; }

.canvas__venn-text {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  font-size: 4.5px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-anchor: middle;
  fill: var(--venn-label-color);
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.3)); }

.canvas__venn-circle {
  fill: var(--venn-fill-color);
  stroke-width: 0.5px;
  stroke: var(--venn-line-color);
  filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.2)); }

.canvas--hide-product .canvas__product {
  opacity: 0; }

.canvas--hide-product .canvas__dotgrid-dot--over-product {
  opacity: 1; }

.canvas--hide-context .canvas__line.canvas__context:not(.canvas__core):not(.canvas__elements) {
  opacity: 0; }

.canvas--hide-context .canvas__marker.canvas__context:not(.canvas__core):not(.canvas__elements) {
  opacity: 0; }

.canvas--hide-context .canvas__dotgrid-dot--over-line.canvas__context:not(.canvas__core):not(.canvas__elements) {
  opacity: 1; }

.canvas--hide-context .canvas__label.canvas__context {
  opacity: 0; }

.canvas--hide-context .canvas__dotgrid-dot--over-label.canvas__context {
  opacity: 1; }

.canvas--hide-core .canvas__line.canvas__core:not(.canvas__context):not(.canvas__product) {
  opacity: 0; }

.canvas--hide-core .canvas__marker.canvas__core:not(.canvas__context):not(.canvas__product) {
  opacity: 0; }

.canvas--hide-core .canvas__dotgrid-dot--over-line.canvas__core:not(.canvas__context) {
  opacity: 1; }

.canvas--hide-core .canvas__label.canvas__core {
  opacity: 0; }

.canvas--hide-core .canvas__dotgrid-dot--over-label.canvas__core {
  opacity: 1; }

.canvas--hide-core.canvas--hide-context .canvas__line.canvas__core.canvas__context {
  opacity: 0; }

.canvas--hide-core.canvas--hide-context .canvas__marker.canvas__core.canvas__context {
  opacity: 0; }

.canvas--hide-core.canvas--hide-context .canvas__dotgrid-dot--over-line.canvas__context.canvas__core {
  opacity: 1; }

.canvas--hide-elements .canvas__line.canvas__element {
  opacity: 0; }

.canvas--hide-elements .canvas__marker.canvas__elements:not(.canvas__context) {
  opacity: 0; }

.canvas--hide-elements .canvas__dotgrid-dot--over-line.canvas__elements:not(.canvas__context) {
  opacity: 1; }

.canvas--hide-elements .canvas__label.canvas__elements {
  opacity: 0; }

.canvas--hide-elements .canvas__dotgrid-dot--over-label.canvas__elements {
  opacity: 1; }

.canvas--hide-context.canvas--hide-elements .canvas__marker.canvas__elements.canvas__context {
  opacity: 0; }

.canvas--hide-context.canvas--hide-elements .canvas__dotgrid-dot--over-line.canvas__context.canvas__elements:not(.canvas__core) {
  opacity: 1; }
