/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.cookie-banner {
  position: fixed;
  bottom: calc(var(--spacing-unit) * 5);
  right: calc(var(--spacing-unit) * 5);
  background-color: var(--gray-900);
  @include shadow-floating;
  color: var(--white);
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3)
    calc(var(--spacing-unit) * 3);
  max-width: calc(var(--spacing-unit) * 60);
  z-index: 200;

  @include for-phone-only {
    right: 0;
    left: 0;
    bottom: 0;
    max-width: 100%;
    width: 100%;

    padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3)
      calc(var(--spacing-unit) * 3);
  }
}

.cookie-banner__actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2);
  margin-top: calc(var(--spacing-unit) * 3);

  @include for-phone-only {
    margin-top: calc(var(--spacing-unit) * 2);
  }
}

.cookie-banner--hidden {
  display: none;
}
