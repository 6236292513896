/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.icon {
  display: block;
  background-color: var(--icon-color);
  mask-image: var(--icon-image);
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-image: var(--icon-image);
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: 50% 50%; }

.icon--tiny {
  height: 12px;
  width: 12px;
  line-height: 12px; }

.icon--alpha {
  height: 16px;
  width: 16px;
  line-height: 16px; }

.icon--beta {
  height: 24px;
  width: 24px;
  line-height: 24px; }

.icon--gamma {
  height: 36px;
  width: 36px;
  line-height: 36px; }

.icon--delta {
  height: 48px;
  width: 48px;
  line-height: 48px; }

.icon--epsilon {
  height: 60px;
  width: 60px;
  line-height: 60px; }

.icon--zeta {
  height: 72px;
  width: 72px;
  line-height: 72px; }

.icon--eta {
  height: 84px;
  width: 84px;
  line-height: 84px; }
