/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.question {
  padding-top: calc(var(--spacing-unit) * 20);
  padding-bottom: calc(var(--spacing-unit) * 15);
  overflow: hidden;

  @include for-tablet-portrait-down {
    padding-top: calc(var(--spacing-unit) * 15);
    padding-bottom: calc(var(--spacing-unit) * 10);
  }

  @include for-phone-only {
    padding-top: calc(var(--spacing-unit) * 12);
  }
}

.question__question {
  grid-column: 3 / -3;
  margin-bottom: calc(var(--spacing-unit) * 20);

  @include typography('eta');

  @include for-tablet-landscape-down {
    grid-column: 2 / -2;
  }

  @include for-tablet-portrait-down {
    margin-bottom: calc(var(--spacing-unit) * 10);
  }

  @include for-phone-only {
    @include typography('epsilon');
    margin-bottom: calc(var(--spacing-unit) * 8);
  }
}

.question__inspiration {
  grid-column: 3 / -3;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 1);
  margin-bottom: calc(var(--spacing-unit) * 5);

  @include for-tablet-landscape-down {
    grid-column: 2 / -2;
  }

  @include for-phone-only {
    grid-template-columns: auto;
    row-gap: calc(var(--spacing-unit) * 1);
    margin-bottom: calc(var(--spacing-unit) * 4);
  }
}

.question__inspiration-intro {
  @include typography($weight: 'medium');
  color: var(--gray-700);

  @include for-phone-only {
    @include typography('alpha', 'medium');
  }
}

.question__answers {
  grid-column: 3 / -3;
  max-width: 960px;

  display: grid;
  grid-template-columns: 1fr calc(var(--spacing-unit) * 50);
  column-gap: calc(var(--spacing-unit) * 3);

  @include for-tablet-landscape-down {
    grid-column: 2 / -2;
    grid-template-columns: 1fr 0;
    column-gap: calc(var(--spacing-unit) * 0);
  }
}

.question__options {
  grid-row: 1;
  grid-column: 2;
}

.question__options-card {
  background-color: var(--gray-100);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2.5);
  position: sticky;
  top: calc(var(--spacing-unit) * 10);
  color: var(--gray-700);
  border: 1px solid var(--gray-200);
}

.question__options-button {
  @include button-reset;
  cursor: pointer;
  color: var(--gray-700);
  transition: color 0.2s;
}

.keyboard-focus .question__options-button:focus {
  @include keyboard-focus;
}

.question__options-button:active {
  color: var(--gray-900);
}

.question__options-button + .question__options-button {
  margin-top: calc(var(--spacing-unit) * 1);
  padding-top: calc(var(--spacing-unit) * 1);
  border-top: 1px solid var(--gray-200);
}

.question__cues {
  grid-column: 1;
  grid-row: 1;
  max-width: calc(var(--spacing-unit) * 120);
}

.share-question-modal {
  max-width: calc(var(--spacing-unit) * 120);
}

.share-question-modal__question {
  padding: calc(var(--spacing-unit) * 8) calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 4)
    calc(var(--spacing-unit) * 5);
  background-color: var(--gray-300);
}

.share-question-modal__form {
  padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 5);
  display: grid;
  row-gap: calc(var(--spacing-unit) * 3);
}

.share-question-modal__button {
  justify-self: end;
}
