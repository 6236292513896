/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.sharing-modal {
  max-width: calc(var(--spacing-unit) * 120);
}

.sharing-modal__form {
  padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 5);
  display: grid;
  row-gap: calc(var(--spacing-unit) * 3);
}

.sharing-modal__button {
  justify-self: end;
}
