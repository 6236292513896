@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.breadcrumbs {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: calc(var(--spacing-unit) * 0.5);
  align-items: center;
  margin-left: calc(var(--spacing-unit) * -0.5); }

.breadcrumbs__tab-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: calc(var(--spacing-unit) * 0.5);
  align-items: center; }

.breadcrumbs__tab {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  outline: none;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 0.2s;
  color: var(--gray-600);
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 0.5);
  text-decoration: none;
  border-bottom: 2px solid transparent; }

.breadcrumbs__tab--active {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  color: var(--black);
  border-bottom: 2px solid var(--highlight); }

.breadcrumbs__tab + .breadcrumbs__tab {
  margin-left: calc(var(--spacing-unit) * 4); }

.breadcrumbs__tab:focus {
  outline: none; }

.keyboard-focus .breadcrumbs__tab:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color);
  border-radius: var(--border-radius); }

.breadcrumbs__tab:active {
  opacity: 0.6; }

.breadcrumbs__chevron {
  color: var(--gray-500); }

.breadcrumbs--theme-light .breadcrumbs__tab {
  color: var(--gray-300); }

.breadcrumbs--theme-light .breadcrumbs__tab--active {
  color: var(--white);
  border-bottom-color: var(--highlight-light); }

.breadcrumbs--theme-highlight .breadcrumbs__tab {
  color: rgba(255, 255, 255, 0.5); }

.breadcrumbs--theme-highlight .breadcrumbs__tab--active {
  color: var(--white);
  border-bottom-color: var(--white); }

.breadcrumbs--theme-plum-compote .breadcrumbs__tab {
  color: rgba(255, 255, 255, 0.5); }

.breadcrumbs--theme-plum-compote .breadcrumbs__tab--active {
  color: var(--white);
  border-bottom-color: var(--lemon-tarte-bright); }
