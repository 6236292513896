/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.timebox-indicator {
  @include button-reset;

  border-radius: var(--border-radius);
  transition: background-color 0.3s;
  cursor: pointer;
  margin-right: calc(var(--spacing-unit) * 3);
  position: relative;
}

.timebox-indicator__content {
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 1.5);
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: calc(var(--spacing-unit) * 1);
  align-items: center;
}

.timebox-indicator {
  .timebox-indicator__icon-default {
    display: block;
  }

  .timebox-indicator__icon-pause {
    display: none;
  }

  .timebox-indicator__icon-play {
    display: none;
  }

  .timebox-indicator__icon-reset {
    display: none;
  }
}

.timebox-indicator:hover,
.keyboard-focus .timebox-indicator:focus {
  background-color: var(--gray-200);

  .timebox-indicator__icon-default {
    display: none;
  }

  .timebox-indicator__icon-pause {
    display: block;
  }

  .timebox-indicator__icon-play {
    display: none;
  }

  .timebox-indicator__icon-reset {
    display: none;
  }

  .timebox-indicator__tooltip {
    opacity: 1;
  }
}

.timebox-indicator--is-paused,
.timebox-indicator--is-paused:hover,
.keyboard-focus .timebox-indicator--is-paused:focus {
  .timebox-indicator__icon-default {
    display: none;
  }

  .timebox-indicator__icon-pause {
    display: none;
  }

  .timebox-indicator__icon-play {
    display: block;
  }

  .timebox-indicator__icon-reset {
    display: none;
  }
}

.keyboard-focus .timebox-indicator:focus {
  @include keyboard-focus;
}

.timebox-indicator--running-out {
  color: var(--stop);
}

.timebox-indicator--ran-out,
.keyboard-focus .timebox-indicator--ran-out:focus {
  background-color: var(--caution);
}

.timebox-indicator--ran-out:hover {
  background-color: darken(map-get($primary, 'caution'), 20) !important;
}

.timebox-indicator--ran-out,
.timebox-indicator--ran-out:hover,
.keyboard-focus .timebox-indicator--ran-out:focus {
  .timebox-indicator__icon-default {
    display: none;
  }

  .timebox-indicator__icon-pause {
    display: none;
  }

  .timebox-indicator__icon-play {
    display: none;
  }

  .timebox-indicator__icon-reset {
    display: block;
  }
}

.timebox-indicator__time {
  font-variant-numeric: tabular-nums;
}

.timebox-indicator__tooltip {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(24px, calc(100% + 2px));
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.timebox-indicator__tooltip-content {
  width: calc(var(--spacing-unit) * 40);
  @include typography('alpha');
  color: var(--gray-400);

  strong {
    @include typography('alpha', 'medium');
    color: var(--white);
    display: block;
  }
}
