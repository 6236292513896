/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.overview {
  margin: 0 auto;
  overflow: hidden;
}

.overview__intro {
  background-color: var(--plum-compote);
  color: var(--white);
  padding-top: calc(var(--spacing-unit) * 20);
  padding-bottom: calc(var(--spacing-unit) * 15);
  position: relative;

  @include for-phone-only {
    padding-top: calc(var(--spacing-unit) * 18);
    padding-bottom: calc(var(--spacing-unit) * 8);
  }
}

.overview__intro-content {
  grid-column: 2 / -2;
  z-index: 1;
}

.overview__intro-kicker {
  @include typography($weight: 'medium');
  color: var(--white);
  opacity: 0.5;
  margin-bottom: calc(var(--spacing-unit) * 1);
}

.overview__intro-title {
  @include typography('eta', $weight: 'medium');
  margin: 0;
  margin-bottom: calc(var(--spacing-unit) * 8);
}

.overview__intro-text {
  color: transparentize(#fff, 0.25);
}

.overview__intro-button {
  margin-top: calc(var(--spacing-unit) * 5);
}

.overview__questions {
  padding-top: calc(var(--spacing-unit) * 20);

  @include for-phone-only {
    padding-top: calc(var(--spacing-unit) * 10);
  }
}

.overview__questions-content {
  grid-column: 2 / -2;
}

.overview-question + .overview-question {
  margin-top: calc(var(--spacing-unit) * 25);

  @include for-phone-only {
    margin-top: calc(var(--spacing-unit) * 15);
  }
}

.overview-question__question {
  @include typography('delta');
  max-width: 26em;

  margin-bottom: calc(var(--spacing-unit) * 10);

  @include for-phone-only {
    margin-bottom: calc(var(--spacing-unit) * 7);
  }
}

.overview-question__aspect {
  color: var(--gray-700);
  margin-bottom: calc(var(--spacing-unit) * 4);

  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 3) auto 1fr;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 1.5);

  @include for-phone-only {
    margin-bottom: calc(var(--spacing-unit) * 2);
    grid-template-columns: auto 1fr;
  }
}

.overview-question__aspect::before {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--gray-300);

  @include for-phone-only {
    display: none;
  }
}

.overview-question__aspect::after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--gray-300);
}

.overview-question__label {
  @include typography('alpha', 'medium');
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  margin-top: calc(var(--spacing-unit) * 5);
}

.overview-question__cue-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(var(--spacing-unit) * 42), 1fr));
  grid-column-gap: calc(var(--spacing-unit) * 3);
  grid-row-gap: calc(var(--spacing-unit) * 3);
}

// .overview-question__cue {
//   width: 25%;
// }

.overview-question__cue-add {
  @include button-reset;
  cursor: pointer;
  width: 100%;
  min-height: 120px;
  border-radius: calc(var(--border-radius) * 2);
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.overview-question__cue-add:active {
  background-color: var(--gray-200);
}

.keyboard-focus .overview-question__cue-add:focus {
  @include keyboard-focus;
}

.overview__outro {
  margin-top: calc(var(--spacing-unit) * 20);
  background-color: var(--plum-compote);
  color: var(--white);
  overflow: hidden;
  position: relative;

  @include for-phone-only {
    margin-top: calc(var(--spacing-unit) * 10);
  }
}

.overview__outro-content {
  grid-column: 2 / -2;
  padding-top: calc(var(--spacing-unit) * 40);
  padding-bottom: calc(var(--spacing-unit) * 20);
  position: relative;

  @include for-phone-only {
    padding-top: calc(var(--spacing-unit) * 12);
  }
}

.overview__outro-title {
  @include typography('eta', $weight: 'medium');
  margin: 0;
  z-index: 1;
  position: relative;
}

.overview__outro-radar {
  position: absolute;
  bottom: calc(var(--spacing-unit) * 3);
  right: calc(var(--spacing-unit) * 8);
}
