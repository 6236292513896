/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.overlay {
  pointer-events: all;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 14, 17, 0.7);
  position: relative;
  position: absolute; }

.overlay__content {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  .overlay__content > * {
    pointer-events: all; }

.overlay--animated {
  animation: fadeIn 0.25s ease-out; }

.overlay--animate-children .overlay__content {
  animation: fadeInContent 0.15s ease-out;
  animation-fill-mode: both; }

@keyframes fadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0); }
  100% {
    background-color: rgba(13, 14, 17, 0.7); } }

@keyframes fadeInContent {
  0% {
    opacity: 0;
    transform: scale(0.96); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.overlay--animated.overlay--fade-out {
  animation: fadeOut 0.2s ease-in 0s;
  animation-fill-mode: both; }

.overlay--animate-children.overlay--fade-out .overlay__content {
  animation: fadeOutContent 0.1s ease-in;
  animation-fill-mode: both; }

@keyframes fadeOut {
  0% {
    background-color: rgba(13, 14, 17, 0.7); }
  100% {
    background-color: rgba(0, 0, 0, 0); } }

@keyframes fadeOutContent {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.96); } }

.overlay--extradark {
  background-color: rgba(13, 14, 17, 0.9); }
