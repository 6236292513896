@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.pagination {
  max-width: 100%;
  display: grid;
  grid-auto-flow: column;
  column-gap: calc(var(--spacing-unit) * 1);
  grid-auto-columns: max-content;
  position: relative;
  overflow: visible;
  height: calc(var(--spacing-unit) * 6);
  align-items: center; }
  .pagination::before {
    content: '';
    height: calc(var(--spacing-unit) * 0.5);
    background-color: var(--gray-300);
    position: absolute;
    top: 50%;
    left: calc(var(--spacing-unit) * 2);
    right: calc(var(--spacing-unit) * 2);
    transform: translateY(-50%); }

.pagination__item {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  cursor: pointer;
  display: block;
  border: 2px solid transparent;
  height: calc(var(--spacing-unit) * 4);
  width: calc(var(--spacing-unit) * 4);
  border-radius: calc(var(--spacing-unit) * 2);
  transition: all 0.3s;
  cursor: pointer;
  position: relative; }
  .pagination__item::after {
    content: '';
    height: calc(var(--spacing-unit) * 1);
    width: calc(var(--spacing-unit) * 1);
    border-radius: calc(var(--spacing-unit) * 0.5);
    background-color: var(--gray-600);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; }
  .pagination__item--active {
    width: calc(var(--spacing-unit) * 4);
    background-color: var(--background-color);
    border-color: var(--highlight-color); }

.keyboard-focus .pagination__item:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pagination__item:active {
  transform: scale(0.8); }

.pagination__fallback {
  display: none; }

.pagination--show-fallback {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em;
  text-align: center;
  color: var(--gray-700);
  font-variant-numeric: tabular-nums; }
  .pagination--show-fallback .pagination__fallback {
    display: block; }
  .pagination--show-fallback .pagination__item {
    display: none; }
  .pagination--show-fallback::before {
    display: none; }

@media (max-width: 1040px) {
  .pagination:not(.pagination--no-fallback) {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 13px;
    line-height: 18px;
    text-underline-offset: 1.5px;
    font-weight: 500;
    letter-spacing: 0.035em;
    text-align: center;
    color: var(--gray-700);
    font-variant-numeric: tabular-nums; }
    .pagination:not(.pagination--no-fallback) .pagination__fallback {
      display: block; }
    .pagination:not(.pagination--no-fallback) .pagination__item {
      display: none; }
    .pagination:not(.pagination--no-fallback)::before {
      display: none; } }

.pagination--dark::before {
  background-color: var(--gray-900); }

.pagination--dark .pagination__fallback {
  color: var(--gray-300); }
