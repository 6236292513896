/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.legacy-tooltip {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4);
  background-color: var(--gray-800);
  border: 1px solid var(--gray-900);
  border-radius: var(--border-radius);
  color: var(--white);
  position: relative; }

.legacy-tooltip__main {
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2); }

.legacy-tooltip__footer {
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 2);
  background-color: var(--gray-900);
  z-index: 2;
  position: relative; }

.legacy-tooltip::after {
  content: '';
  height: 8px;
  width: 8px;
  display: block;
  background-color: var(--gray-800);
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: 1; }

.legacy-tooltip--top-center::after {
  top: 0;
  left: 50%; }

.legacy-tooltip--top-left::after {
  top: 0;
  left: calc(var(--spacing-unit) * 2); }

.legacy-tooltip--top-right::after {
  top: 0;
  left: calc(100% - var(--spacing-unit) * 2); }

.legacy-tooltip--bottom-center::after {
  top: 100%;
  left: 50%; }

.legacy-tooltip--bottom-left::after {
  top: 100%;
  left: calc(var(--spacing-unit) * 2); }

.legacy-tooltip--bottom-right::after {
  top: 100%;
  left: calc(100% - var(--spacing-unit) * 2); }

.legacy-tooltip--left-center::after {
  left: 0;
  top: 50%; }

.legacy-tooltip--left-top::after {
  left: 0;
  top: calc(var(--spacing-unit) * 2); }

.legacy-tooltip--left-bottom::after {
  left: 0;
  top: calc(100% - var(--spacing-unit) * 2); }

.legacy-tooltip--right-center::after {
  left: 100%;
  top: 50%; }

.legacy-tooltip--right-top::after {
  left: 100%;
  top: calc(var(--spacing-unit) * 2); }

.legacy-tooltip--right-bottom::after {
  left: 100%;
  top: calc(100% - var(--spacing-unit) * 2); }

.legacy-tooltip--has-footer.legacy-tooltip--bottom-center::after,
.legacy-tooltip--has-footer.legacy-tooltip--bottom-left::after,
.legacy-tooltip--has-footer.legacy-tooltip--bottom-right::after {
  background-color: var(--gray-900); }

.legacy-tooltip--has-footer.legacy-tooltip--left-bottom::after,
.legacy-tooltip--has-footer.legacy-tooltip--right-bototm::after {
  background-color: var(--gray-900); }

.legacy-tooltip--light {
  background-color: var(--white);
  border-color: var(--gray-400);
  color: var(--black); }

.legacy-tooltip--light::after {
  background-color: var(--white); }

.legacy-tooltip--no-padding .legacy-tooltip__main {
  padding: 0; }
