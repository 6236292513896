@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.user-avatar {
  display: block;
  overflow: hidden;
  border-radius: 50%;
  border-style: solid;
  border-width: 0px;
  width: calc(var(--spacing-unit) * 5);
  height: calc(var(--spacing-unit) * 5); }

.user-avatar--alpha {
  height: calc(var(--spacing-unit) * 4);
  width: calc(var(--spacing-unit) * 4); }

.user-avatar--beta {
  height: calc(var(--spacing-unit) * 5);
  width: calc(var(--spacing-unit) * 5); }

.user-avatar--gamma {
  height: calc(var(--spacing-unit) * 7);
  width: calc(var(--spacing-unit) * 7); }

.user-avatar--delta {
  height: calc(var(--spacing-unit) * 9);
  width: calc(var(--spacing-unit) * 9); }

.user-avatar--epsilon {
  height: calc(var(--spacing-unit) * 11);
  width: calc(var(--spacing-unit) * 11); }

.user-avatar--zeta {
  height: calc(var(--spacing-unit) * 14);
  width: calc(var(--spacing-unit) * 14); }

.user-avatar--eta {
  height: calc(var(--spacing-unit) * 18);
  width: calc(var(--spacing-unit) * 18); }

.user-avatar__image,
.user-avatar__fallback {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50%; }

.user-avatar__fallback {
  --background: var(--raspberry-sorbet);
  --color: var(--raspberry-sorbet-dark);
  background-color: var(--background);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  line-height: 1em; }
  .user-avatar__fallback text {
    font-size: 20px;
    letter-spacing: 0.03em;
    fill: var(--color); }
  .user-avatar__fallback--1, .user-avatar__fallback--6 {
    --background: var(--raspberry-sorbet);
    --color: var(--raspberry-sorbet-dark); }
  .user-avatar__fallback--2, .user-avatar__fallback--7 {
    --background: var(--lime-pie);
    --color: var(--lime-pie-dark); }
  .user-avatar__fallback--3, .user-avatar__fallback--8 {
    --background: var(--peach-crumble);
    --color: var(--peach-crumble-dark); }
  .user-avatar__fallback--4 {
    --background: var(--lemon-tarte);
    --color: var(--lemon-tarte-dark); }
  .user-avatar__fallback--5, .user-avatar__fallback--9 {
    --background: var(--blueberry-pancakes);
    --color: var(--blueberry-pancakes-dark); }
