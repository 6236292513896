/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.sortable-checkbox-list {
  margin: calc(var(--spacing-unit) * 1.5) 0; }

.sortable-checkbox-list-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
  margin-top: calc(var(--spacing-unit) * 1);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 3);
  padding-left: calc(var(--spacing-unit) * 2);
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08);
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  transition: box-shadow 0.2s, border-color 0.2s, color 0.2s; }

.sortable-checkbox-list-button:focus {
  outline: none; }

.keyboard-focus .sortable-checkbox-list-button:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.sortable-checkbox-list-button--disabled {
  box-shadow: none;
  border-color: var(--gray-200);
  color: var(--gray-700); }

.sortable-checkbox-list-button__drag-handle {
  margin-right: calc(var(--spacing-unit) * 3);
  width: calc(var(--spacing-unit) * 3);
  height: calc(var(--spacing-unit) * 1.5);
  border-top: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
  position: relative; }

.sortable-checkbox-list-button__drag-handle::before {
  content: '';
  position: absolute;
  top: calc(50% - 0.5px);
  left: 0;
  right: 0;
  border-top: 1px solid var(--gray-400); }

.sortable-checkbox-list-button__input {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
  padding: 0; }

.sortable-checkbox-list-button__indicator {
  width: calc(var(--spacing-unit) * 3.5);
  height: calc(var(--spacing-unit) * 3.5);
  flex-shrink: 0;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius);
  margin-right: 9px;
  position: relative;
  transition: all 0.15s;
  align-self: start;
  margin-top: 1.5px; }

.sortable-checkbox-list-button__checkmark {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 65%;
  width: 65%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  transition: all 0.15s;
  stroke: var(--white); }

.sortable-checkbox-list-button__input:checked ~ .sortable-checkbox-list-button__indicator {
  background-color: var(--highlight);
  border-color: var(--highlight-dark); }

.sortable-checkbox-list-button__input:checked
~ .sortable-checkbox-list-button__indicator
.sortable-checkbox-list-button__checkmark {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1; }

.keyboard-focus .sortable-checkbox-list-button__input:focus ~ .sortable-checkbox-list-button__indicator {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.sortable-checkbox-list--disabled {
  opacity: 0.3; }

.sortable-checkbox-list--disabled .sortable-checkbox-list-button {
  cursor: default; }

.sortable-checkbox-list--disabled
.sortable-checkbox-list-button__input:checked
~ .sortable-checkbox-list-button__indicator {
  background-color: var(--gray-600);
  border-color: var(--gray-700); }
