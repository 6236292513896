@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.loading-view {
  height: 100%;
  width: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  background-color: var(--gray-900);
  color: var(--white);
  overflow: hidden; }

.loading-view__content {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 5);
  opacity: 0;
  transform: scale(1.4);
  transition: all 0.4s ease-out; }

.loading-view--show .loading-view__content {
  opacity: 1;
  transform: scale(1); }

.loading-view__text {
  animation: loading-view-pulse 3.4s infinite;
  margin-top: calc(var(--spacing-unit) * 3); }

@keyframes loading-view-pulse {
  0% {
    opacity: 1; }
  70% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

.loading-view--light {
  background-color: var(--gray-100);
  color: var(--gray-800); }

.loading-view--white {
  background-color: var(--white);
  color: var(--black); }

.loading-view--highlight {
  background-color: var(--highlight);
  color: var(--white); }

.loading-view--public {
  background-color: var(--highlight-background);
  color: rgba(0, 0, 0, 0.7); }
