/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.autogrowing-input {
  position: relative;
  padding-right: 2px; }

.autogrowing-input__input {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block; }

.autogrowing-input__spacer {
  opacity: 0;
  white-space: nowrap;
  pointer-events: none; }
