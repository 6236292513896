/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.app-frame {
  --cue-background-color: var(--lime-pie);
  --cue-border-color: #{darken(map-get($secondary, 'lime-pie'), 10)};
  --create-cue-background-color: var(--gray-200);
  --create-cue-border-color: #{darken(map-get($grayscale, 'gray-200'), 3)};
  --create-cue-extra-height: calc(var(--spacing-unit) * 4);

  @include for-phone-only {
    --create-cue-extra-height: calc(var(--spacing-unit) * 2.5);
  }
}

.cue {
  border-radius: calc(var(--border-radius) * 3);
  @include typography('gamma');
  line-height: 36px;
  background-color: var(--cue-background-color);
  border: 1px solid var(--cue-border-color);

  @include for-phone-only {
    line-height: 36px;
  }
}

.app-frame:not(.keyboard-focus) .cue:focus-within,
.app-frame:not(.keyboard-focus) .cue--create.cue:focus-within {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px lighten(map-get($primary, 'highlight'), 32),
    0 0 9px 0 lighten(map-get($primary, 'highlight'), 32);
}

.keyboard-focus .cue:focus-within {
  @include keyboard-focus;
}

.cue__body {
  position: relative;
  padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4) 0;

  @include for-phone-only {
    padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 2.5) 0;
  }
}

.cue__body::before {
  content: '';
  position: absolute;
  top: calc(36px + var(--spacing-unit) * 3);
  left: calc(var(--spacing-unit) * 4);
  right: 0;
  bottom: -1px;
  background: linear-gradient(0deg, transparent 5em, rgba(255, 0, 0, 0) 0, transparent 5.1em),
    linear-gradient(rgba(0, 0, 0, 0.15) 1px, transparent 0);
  background-size: 100% 36px;
  pointer-events: none;

  @include for-phone-only {
    top: calc(36px + var(--spacing-unit) * 0.5);
    left: calc(var(--spacing-unit) * 2.5);
  }
}

.cue__input {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  border: 0;
  width: 100%;
  background-color: transparent;
  outline: none;
  display: block;
  resize: none;
  color: inherit;
}

.cue__input::placeholder {
  color: var(--lime-pie-dark);
}

.cue__footer-create {
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: calc(var(--spacing-unit) * 3);
  align-items: center;
  padding-top: calc(var(--spacing-unit) * 3);
  padding-left: calc(var(--spacing-unit) * 4);
  padding-right: calc(var(--spacing-unit) * 5);
  padding-bottom: calc(var(--spacing-unit) * 4);
  display: none;

  @include for-phone-only {
    padding-left: calc(var(--spacing-unit) * 2.5);
    padding-right: calc(var(--spacing-unit) * 2.5);
    padding-bottom: calc(var(--spacing-unit) * 2.5);
  }
}

.cue__line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.cue__footer {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: calc(var(--spacing-unit) * 2);
  align-items: center;
  padding: calc(var(--spacing-unit) * 2);
  padding-left: calc(var(--spacing-unit) * 4);
  padding-top: calc(var(--spacing-unit) * 3);

  @include for-phone-only {
    padding-left: calc(var(--spacing-unit) * 2.5);
  }
}

.cue__anonymized-icon {
  line-height: 16px;
}

.cue__meta {
  opacity: 0.5;
  @include typography('alpha');
}

.cue__options {
  position: relative;
  @include typography('beta');
}

.cue__options-button {
  padding: 0 calc(var(--spacing-unit) * 1.5);
  background-color: transparentize(map-get($secondary, 'lime-pie-dark'), 0.7);
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  display: block;
}

.cue__options-button:hover:not(.cue__options-button--active) {
  background-color: transparentize(map-get($secondary, 'lime-pie-dark'), 0.58);
}

.keyboard-focus .cue__options-button:focus {
  @include keyboard-focus;
}

.cue__options-button:active,
.cue__options-button--active,
.cue__options-button:active:hover {
  background-color: transparentize(map-get($secondary, 'lime-pie-dark'), 0.37);
}

.cue__options-icon {
  opacity: 0.5;
}

/* --- CREATE */

.cue--create {
  background-color: var(--create-cue-background-color);
  border-color: var(--create-cue-border-color);
}

.cue--create .cue__input::placeholder {
  color: var(--gray-500);
}

.cue--create .cue__footer-create {
  display: grid;
}

.cue--create .cue__footer {
  display: none;
}

/* --- ANONYMIZED */

.cue--anonymized {
  background-color: var(--gray-300);
  border: 1px solid var(--gray-400);
}

.cue--anonymized .cue__input::placeholder {
  color: var(--gray-600);
}

.cue--anonymized .cue__footer {
  grid-template-columns: auto 1fr auto;
}

.cue--anonymized .cue__options-button {
  background-color: transparentize(black, 0.9);
}

.cue--anonymized .cue__options-button:hover:not(.cue__options-button--active) {
  background-color: transparentize(black, 0.86);
}

.cue--anonymized .cue__options-button:active,
.cue--anonymized .cue__options-button--active,
.cue--anonymized .cue__options-button:active:hover {
  background-color: transparentize(black, 0.77);
}

/* --- SMALL */

.cue--small {
  @include typography('beta');
  line-height: 27px;
  width: 100%;
  border-radius: calc(var(--border-radius) * 2);
}

.cue--small .cue__body {
  padding: calc(var(--spacing-unit) * 1.2) calc(var(--spacing-unit) * 3) 0 calc(var(--spacing-unit) * 2.5);
}

.cue--small .cue__body::before {
  position: absolute;
  top: calc(27px + var(--spacing-unit) * 1.2);
  left: calc(var(--spacing-unit) * 2.5);
  right: 0;
  bottom: -1px;
  background-size: 100% 27px;
  pointer-events: none;
}

.cue--small .cue__footer-create {
  display: none;
}

.cue--small .cue__footer {
  height: auto;
  grid-template-columns: auto;
  align-items: center;
  justify-content: end;
  padding: calc(var(--spacing-unit) * 1);
  padding-left: calc(var(--spacing-unit) * 2.5);
}

.cue--small.cue--anonymized .cue__footer {
  grid-template-columns: 1fr auto;
}

.cue--small .cue__meta {
  display: none;
}

.cue--small .cue__options-button {
  padding: 1px calc(var(--spacing-unit) * 1.5);
}
