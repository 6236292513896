/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.default-layout {
  grid-auto-rows: auto;
  overflow: hidden;
}

.default-layout__ornament {
  grid-row: 1/5;
  grid-column: 10 / -1;
  background-color: var(--peach-crumble);

  min-height: 100vh;

  position: relative;

  @include for-tablet-landscape-down {
    grid-column: 12 / -1;
  }

  @include for-tablet-portrait-down {
    display: none;
  }

  .key-visual {
    opacity: 0.06;
  }
}

.default-layout__ornament--lime-pie {
  background-color: var(--lime-pie);
}

.default-layout-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: calc(var(--spacing-unit) * 7);

  align-items: center;
  z-index: 100;
}

@media (min-width: 1548px) {
  .default-layout-header {
    grid-template-columns: 1fr repeat(8, calc(var(--spacing-unit) * 20));
  }
}

.default-layout-header__logo {
  grid-column: 2 / 3;
  grid-row: 1;
  @include typography($type: 'wide', $weight: 'medium');

  @include for-tablet-landscape-down {
    grid-column: 2 / 5;
  }
}

.default-layout-header__accessory {
  grid-column: 3 / 10;
  grid-row: 1;
  text-align: right;
  color: var(--gray-700);
  padding-right: calc(var(--spacing-unit) * 9);

  @include for-tablet-landscape-down {
    grid-column: 5 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 5 / -2;
    padding-right: 0;
  }

  @include for-phone-only {
    @include typography('alpha');
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.default-layout-header__background {
  grid-row: 1;
  grid-column: 1 / 10;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-300);
  height: 100%;

  @include for-tablet-landscape-down {
    grid-column: 1 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 1 / -1;
  }
}
