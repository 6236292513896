/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.cue-character-counter {
  @include typography('alpha', 'medium');
  font-variant-numeric: tabular-nums;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 1);
  position: relative;
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 1);
  border-radius: var(--border-radius);
  transition: all 0.3s;
}

.cue-character-counter--too-long {
  color: var(--stop);
}

.cue-character-counter__count {
  width: calc(var(--spacing-unit) * 4);
  text-align: right;
  transition: all 0.3s;
}

.cue-character-counter__circle-chart {
  width: calc(var(--spacing-unit) * 3);
  height: calc(var(--spacing-unit) * 3);
}

.cue-character-counter__circle-chart-background {
  stroke: var(--gray-400);
}

.cue-character-counter__circle-chart-circle {
  stroke: var(--gray-700);
  transform: rotate(-90deg);
  transform-origin: center;
  transition: all 0.3s;
}

.cue-character-counter--too-long .cue-character-counter__circle-chart-circle {
  stroke: var(--stop);
}

.cue-character-counter__tooltip-content {
  width: calc(var(--spacing-unit) * 40);
  @include typography('alpha');
  color: var(--gray-400);

  strong {
    @include typography('alpha', 'medium');
    color: var(--white);
  }
}

.cue-character-counter:hover,
.cue-character-counter:focus,
.cue-character-counter--almost-too-long {
  background-color: var(--gray-300);
  outline: none;

  .cue-character-counter__count {
    opacity: 1 !important;
  }
}

.keyboard-focus .cue-character-counter:focus {
  @include keyboard-focus;
}
