/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

@import '~@fieldbyfield/components/build/fundaments';

.default-page__intro {
  grid-column: 2 / 10;
  grid-row: 1;
  padding-top: calc(var(--spacing-unit) * 16);
  padding-bottom: calc(var(--spacing-unit) * 12);
  padding-right: calc(var(--spacing-unit) * 10);

  @include for-tablet-landscape-down {
    grid-column: 2 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 2 / -2;
    padding-right: calc(var(--spacing-unit) * 0);
  }
}

.default-page__intro-title {
  @include typography('eta', $weight: 'medium');
  margin: 0;
  margin-bottom: calc(var(--spacing-unit) * 8);
}

.default-page__intro-text {
  color: var(--gray-700);
  hyphens: auto;
}

.default-page__info {
  grid-row: 2;
  grid-column: 2 / 8;

  padding-right: calc(var(--spacing-unit) * 10);
  padding-top: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 10);

  display: grid;
  row-gap: calc(var(--spacing-unit) * 6);

  @include for-tablet-landscape-down {
    grid-column: 2 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 2 / -2;
    padding-right: calc(var(--spacing-unit) * 0);
  }

  @include for-phone-only {
    padding-bottom: calc(var(--spacing-unit) * 20);
  }
}

.default-page__info-background {
  grid-row: 2;
  grid-column: 1 / 10;
  background-color: var(--gray-100);

  @include for-tablet-landscape-down {
    grid-column: 1 / 12;
  }

  @include for-tablet-portrait-down {
    grid-column: 1 / -1;
  }
}
