@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.paragraph {
  max-width: 36em;
  margin-top: 0; }

.paragraph:last-child {
  margin-bottom: 0; }

.paragraph--alpha {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  margin-bottom: 6px;
  max-width: 30em; }

.paragraph--alpha.paragraph--medium,
.paragraph--alpha strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em; }

.paragraph--beta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: 0.009em;
  margin-bottom: 6px; }

.paragraph--beta.paragraph--medium,
.paragraph--beta strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em; }

.paragraph--gamma {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 22px;
  line-height: 30px;
  text-underline-offset: 2px;
  font-weight: 400;
  letter-spacing: -0.005em;
  max-width: 30em;
  margin-bottom: 12px; }
  @media (max-width: 599px) {
    .paragraph--gamma {
      font-size: 20px;
      line-height: 28px; } }

.paragraph--gamma.paragraph--medium,
.paragraph--gamma strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 22px;
  line-height: 30px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.001em; }
  @media (max-width: 599px) {
    .paragraph--gamma.paragraph--medium,
    .paragraph--gamma strong {
      font-size: 20px;
      line-height: 28px; } }

.paragraph--delta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 30px;
  line-height: 36px;
  text-underline-offset: 3px;
  font-weight: 400;
  letter-spacing: -0.01em;
  max-width: 30em;
  margin-bottom: 12px; }
  @media (max-width: 599px) {
    .paragraph--delta {
      font-size: 22px;
      line-height: 28px; } }

.paragraph--delta.paragraph--medium,
.paragraph--delta strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 30px;
  line-height: 36px;
  text-underline-offset: 3px;
  font-weight: 500;
  letter-spacing: -0.004em; }
  @media (max-width: 599px) {
    .paragraph--delta.paragraph--medium,
    .paragraph--delta strong {
      font-size: 22px;
      line-height: 28px; } }

.paragraph--epsilon {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-underline-offset: 3px;
  text-decoration-thickness: 500%;
  font-weight: 400;
  letter-spacing: -0.015em;
  max-width: 28em;
  margin-bottom: 18px; }
  @media (max-width: 599px) {
    .paragraph--epsilon {
      font-size: 26px;
      line-height: 33px; } }

.paragraph--epsilon.paragraph--medium,
.paragraph--epsilon strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-underline-offset: 3px;
  text-decoration-thickness: 500%;
  font-weight: 500;
  letter-spacing: -0.013em; }
  @media (max-width: 599px) {
    .paragraph--epsilon.paragraph--medium,
    .paragraph--epsilon strong {
      font-size: 26px;
      line-height: 33px; } }

.paragraph--zeta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 44px;
  line-height: 48px;
  text-underline-offset: 4px;
  font-weight: 400;
  letter-spacing: -0.017em;
  max-width: 24em;
  margin-bottom: 24px; }
  @media (max-width: 599px) {
    .paragraph--zeta {
      font-size: 32px;
      line-height: 35px; } }

.paragraph--zeta.paragraph--medium,
.paragraph--zeta strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 44px;
  line-height: 48px;
  text-underline-offset: 4px;
  font-weight: 500;
  letter-spacing: -0.015em; }
  @media (max-width: 599px) {
    .paragraph--zeta.paragraph--medium,
    .paragraph--zeta strong {
      font-size: 32px;
      line-height: 35px; } }

.paragraph--eta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 62px;
  line-height: 66px;
  text-underline-offset: 4px;
  font-weight: 400;
  letter-spacing: -0.03em;
  max-width: 22em;
  margin-bottom: 36px; }
  @media (max-width: 900px) {
    .paragraph--eta {
      font-size: 51px;
      line-height: 54px; } }
  @media (max-width: 599px) {
    .paragraph--eta {
      font-size: 36px;
      line-height: 42px; } }

.paragraph--eta.paragraph--medium,
.paragraph--eta strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 62px;
  line-height: 66px;
  text-underline-offset: 4px;
  font-weight: 500;
  letter-spacing: -0.016em; }
  @media (max-width: 900px) {
    .paragraph--eta.paragraph--medium,
    .paragraph--eta strong {
      font-size: 51px;
      line-height: 54px; } }
  @media (max-width: 599px) {
    .paragraph--eta.paragraph--medium,
    .paragraph--eta strong {
      font-size: 36px;
      line-height: 42px; } }

.paragraph--theta {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 74px;
  line-height: 76px;
  text-underline-offset: 5px;
  font-weight: 400;
  letter-spacing: -0.03em;
  max-width: 20em;
  margin-bottom: 42px; }
  @media (max-width: 900px) {
    .paragraph--theta {
      font-size: 63px;
      line-height: 60px; } }
  @media (max-width: 599px) {
    .paragraph--theta {
      font-size: 51px;
      line-height: 54px; } }

.paragraph--theta.paragraph--medium,
.paragraph--theta strong {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 74px;
  line-height: 76px;
  text-underline-offset: 5px;
  font-weight: 500;
  letter-spacing: -0.022em; }
  @media (max-width: 900px) {
    .paragraph--theta.paragraph--medium,
    .paragraph--theta strong {
      font-size: 63px;
      line-height: 60px; } }
  @media (max-width: 599px) {
    .paragraph--theta.paragraph--medium,
    .paragraph--theta strong {
      font-size: 51px;
      line-height: 54px; } }
