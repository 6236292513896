@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.alert {
  background-color: var(--gray-900);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4);
  margin: calc(var(--spacing-unit) * 9);
  width: 100%;
  max-width: calc(var(--spacing-unit) * 90); }

.alert::after {
  content: '';
  display: block;
  background-color: var(--stop);
  width: 100%;
  height: var(--spacing-unit); }

.alert__content {
  padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4);
  display: grid;
  grid-template-areas: 'icon body';
  column-gap: calc(var(--spacing-unit) * 4);
  grid-template-columns: auto 1fr; }

.alert__icon {
  grid-area: icon; }

.alert__body {
  grid-area: body; }

.alert__message {
  color: var(--gray-400);
  max-width: 25em; }

.alert__headline {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-underline-offset: 2px;
  font-weight: 500;
  letter-spacing: 0.016em;
  color: var(--white); }

.alert__check {
  margin-top: calc(var(--spacing-unit) * 4);
  margin-bottom: calc(var(--spacing-unit) * 6);
  color: var(--white); }

.alert__actions {
  margin-top: calc(var(--spacing-unit) * 4); }
