@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.character-count-indicator {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 500;
  letter-spacing: 0.035em;
  font-variant-numeric: tabular-nums;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 1);
  position: relative;
  padding: 0 calc(var(--spacing-unit) * 0.5);
  border-radius: var(--border-radius);
  transition: all 0.3s; }

.character-count-indicator--too-long {
  color: var(--stop); }

.character-count-indicator__count {
  min-width: 1.3em;
  text-align: right;
  transition: all 0.3s; }

.character-count-indicator__circle-chart {
  width: calc(var(--spacing-unit) * 2.5);
  height: calc(var(--spacing-unit) * 2.5); }

.character-count-indicator__circle-chart-background {
  stroke: currentColor;
  opacity: 0.3; }

.character-count-indicator__circle-chart-circle {
  stroke: currentColor;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: all 0.3s; }

.character-count-indicator--too-long .character-count-indicator__circle-chart-circle {
  stroke: var(--stop); }

.character-count-indicator__tooltip-content {
  width: calc(var(--spacing-unit) * 40);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
  font-size: 13px;
  line-height: 18px;
  text-underline-offset: 1.5px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: var(--gray-400); }
  .character-count-indicator__tooltip-content strong {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-family: "Söhne", Helvetica, Chalkboard, -apple-system, sans-serif;
    font-size: 13px;
    line-height: 18px;
    text-underline-offset: 1.5px;
    font-weight: 500;
    letter-spacing: 0.035em;
    color: var(--white); }

.character-count-indicator:hover,
.character-count-indicator:focus,
.character-count-indicator--too-long {
  background-color: rgba(0, 0, 0, 0.1);
  outline: none; }
  .character-count-indicator:hover .character-count-indicator__count,
  .character-count-indicator:focus .character-count-indicator__count,
  .character-count-indicator--too-long .character-count-indicator__count {
    opacity: 1 !important; }

.keyboard-focus .character-count-indicator:focus {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }
