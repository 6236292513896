/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
.keyboard-focus-demo {
  --focus-color: var(--keyboard-focus);
  box-shadow: 0 0 0 3px var(--focus-color), 0 0 9px 0 var(--focus-color); }

.pointer-focus-demo {
  border: 1px solid var(--highlight);
  box-shadow: 0 0 0 3px rgba(37, 96, 235, 0.32), 0 0 9px 0 rgba(37, 96, 235, 0.32); }

.invalid-demo {
  border: 1px solid var(--stop);
  box-shadow: 0 0 0 3px #ffbfbd, 0 0 9px 0 #ffbfbd; }

.shadow-card-demo {
  box-shadow: 0 7px 8px -8px rgba(31, 33, 40, 0.3); }

.shadow-bar-demo {
  box-shadow: 0 3px 10px -7px rgba(31, 33, 40, 0.4); }

.shadow-floating-demo {
  box-shadow: 0 6px 18px 0 rgba(31, 33, 40, 0.4); }

.shadow-draggable-demo {
  box-shadow: 0 2px 4px 0 rgba(33, 32, 40, 0.08); }

.shadow-draggable--dragging-demo {
  box-shadow: 0 6px 7px 0 rgba(33, 32, 40, 0.12); }

.loading-spinner {
  --spinner-color: var(--highlight);
  --spinner-width: calc(var(--spacing-unit) * 0.5);
  --spinner-border: var(--spinner-color) transparent var(--spinner-color) transparent;
  display: inline-block;
  width: calc(var(--spacing-unit) * 10);
  height: calc(var(--spacing-unit) * 10); }

.loading-spinner--size-alpha {
  width: calc(var(--spacing-unit) * 2.5);
  height: calc(var(--spacing-unit) * 2.5);
  --spinner-width: 2px;
  --spinner-border: var(--spinner-color) var(--spinner-color) transparent; }

.loading-spinner:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: var(--spinner-width) solid var(--spinner-color);
  border-color: var(--spinner-border);
  animation: spinning 1.2s linear infinite; }

@keyframes spinning {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loading-spinner--theme-dark {
  --spinner-color: var(--black); }

.loading-spinner--theme-light {
  --spinner-color: var(--white); }
